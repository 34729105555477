export const enum RequestQueryMapperStrategy {
    Append = 'append',
    Set = 'set'
};

export const enum Aggregates {
    GroupBys = 'groupBys',
    Subtotals = 'subtotals',
    Subavgs = 'subavgs',
    CountRelations = 'countRelations',
    SumsRelations = 'sumsRelations',
    Havings = 'havings',
    Submins = 'submins',
    Submaxs = 'submaxs'
};

export const enum SortOrder {
    Asc = 'ASC',
    Desc = 'DESC'
};
