import { useAppDispatch, useRequest } from "hooks";
import { ApiRouteTypes } from "consts/enpoints/api";
import useExportTable from "hooks/useExportTable";
import type { ResourceResponse, ThunkReturnType } from "types";
import {
    addConsumersEntity,
    updateConsumersEntity,
    changeConsumersExportLoading as changeExportLoading
} from "../slice";
import type { Consumer, ConsumersResponse } from "../types";
import { getConsumersThunk, postConsumerThunk } from "../thunks";

const useConsumersActions = () => {
    const dispatch = useAppDispatch();

    const getConsumers = useRequest<ThunkReturnType<ConsumersResponse>, string>({
        thunk: getConsumersThunk
    });

    const postConsumer = useRequest<ThunkReturnType<ResourceResponse<Consumer>>, Partial<Consumer>>({
        thunk: postConsumerThunk
    });

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetConsumers
    });

    const updateConsumerById = (consumer: Partial<Consumer>) =>
        dispatch(updateConsumersEntity(consumer));

    const addConsumer = (consumer: Consumer) =>
        dispatch(addConsumersEntity(consumer));

    return {
        getConsumers,
        postConsumer,
        downloadCsv,
        addConsumer,
        updateConsumerById
    };
};

export default useConsumersActions;
