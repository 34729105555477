import { memo } from "react";
import { MenuItem } from "consts/consumers";
import type { BlacklistedConsumer } from "features/consumers/types";
import { Action } from "ui/organizms/Consumers/ConsumerActions";
import useWhitelist from "./useWhitelist";

const Whitelist = () => (
    <Action
        <BlacklistedConsumer>
        group={MenuItem.Blacklisted}
        TriggerComponentProps={{
            children: 'Whitelist'
        }}
        onConfirm={useWhitelist()}
    >
        By confirming this action - the user(s) will be removed from the blacklist and whitelisted so they can process.
    </Action>
);

export default memo(Whitelist);
