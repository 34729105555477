import { memo } from "react";
import Screen from "ui/layouts/Screen";
import { AllConsumers } from "ui/organizms/Consumers";

const Consumers = () => (
    <Screen
        title='Consumers | All'
    >
        <AllConsumers />
    </Screen>
);

export default memo(Consumers);
