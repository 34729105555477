import { memo } from "react";
import { Outlet } from "react-router-dom";
import ConsumerProvider from "./ConsumerProvider";
import ConsumerDialogs from "./ConsumerDialogs";

const Consumers = () => (
    <ConsumerProvider>
        <Outlet />
        <ConsumerDialogs />
    </ConsumerProvider>
);

export default memo(Consumers);
