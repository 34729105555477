import { memo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ApiRoutes } from "consts/enpoints/api";
import { MaxFileSizeBytes } from "consts/consumers";
import useActions from "features/general/useActions";
import { NotificationSeverity } from "features/general/types";
import { Blacklist } from "ui/forms/Consumer";
import FileUploader, { type FileUploaderProps } from "ui/widgets/FileUploader";
import ImportBlacklistTemplate from "./ImportBlacklistTemplate";

type Props = Pick<
    FileUploaderProps,
    | 'TriggerComponent'
    | 'TriggerComponentProps'
    | 'onSuccess'
    | 'onError'
>;

const ImportBlacklist = ({
    TriggerComponentProps,
    onSuccess,
    onError,
    ...restProps
}: Props) => {
    const { showNotication } = useActions();
    const blacklistForm = Blacklist.useForm();

    const {
        isValid,
        values,
        resetForm
    } = blacklistForm;

    const handleSuccess: FileUploaderProps['onSuccess'] = response => {
        showNotication({
            message: 'Blacklist imported successfully',
            severity: NotificationSeverity.Success
        });

        resetForm();

        onSuccess?.(response);
    };

    const handleError: FileUploaderProps['onError'] = response => onError?.(response);

    const supportedFormatsMessage = (
        <Typography
            sx={{
                fontFamily: 'var(--primary-font)',
                fontSize: 12,
                color: 'grey.500',
                fontWeight: 600,
                textDecoration: 'underline'
            }}
            gutterBottom
        >
            Supported formats: .csv, UTF-8 encoded, comma-separated
        </Typography>
    );

    return (
        <FileUploader
            url={ApiRoutes.PostImportConsumerBlacklist}
            getBody={() => ({
                ...values,
                [Blacklist.FormField.Reason]: (
                    String(values.otherReason).trim() ||
                    values.reason
                ),
                [Blacklist.FormField.OtherReason]: undefined
            })}
            onSuccess={handleSuccess}
            onError={handleError}
            ModalProps={{
                onClose: () => resetForm(),
                disableCloseOnBackdropClick: true,
                titleSlot: 'Upload Blacklist(s) CSV'
            }}
            FileDropAreaProps={{
                children: supportedFormatsMessage,
                maxSize: MaxFileSizeBytes.Blacklist
            }}
            UploadButtonProps={{
                disabled: !isValid
            }}
            after={(
                <Blacklist.Form
                    {...blacklistForm}
                    sx={{
                        mt: 2
                    }}
                />
            )}
            {...restProps}
            TriggerComponentProps={{
                children: (
                    <>
                        Blacklist&nbsp;<Box sx={{ textTransform: 'lowercase' }}>via</Box>&nbsp;CSV
                    </>
                ),
                sx: {
                    bgcolor: 'black',
                    ':hover': {
                        bgcolor: 'black'
                    }
                },
                ...TriggerComponentProps
            }}
        >
            <Box
                sx={{
                    ml: 1,
                    mb: 2
                }}
            >
                {supportedFormatsMessage}
                <ImportBlacklistTemplate />
            </Box>
        </FileUploader>
    );
};

export default memo(ImportBlacklist);
