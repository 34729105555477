import { useEffect } from "react";
import { delay } from "util/support";
import { events } from "ui/widgets/Table";

export default function useRefetchFiltersResource() {
    useEffect(() => delay.cancel, []);

    return (delayMilliseconds = 1000) => (
        delay(delayMilliseconds)
            .then(events.apply)
    );
};
