import { useMemo } from "react";
import { object, string } from "yup";
import { FormField } from "./types";

export default function useValidationSchema() {
    return useMemo(() => object().shape({
        [FormField.Merchant]: object()
            .required()
            .nullable()
            .label('Merchant'),
        [FormField.Domain]: string()
            .max(255)
            .label('Domains')
    }), []);
};
