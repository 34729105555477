import { memo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getFormInputProps } from "util/forms";
import MerchantSelector from "ui/widgets/MerchantSelector";
import {
    FormField,
    type WhitelistProps
} from "./types";
import type { Option } from "types";

const Whitelist = ({
    values,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
    sx,
    component,
    ...form
}: WhitelistProps) => (
    <Box
        sx={{
            display: 'grid',
            gap: 2,
            ...sx
        }}
        component={component}
    >
        <MerchantSelector
            value={values[FormField.Merchant]}
            onChange={(_, merchant: Option) => {
                setFieldValue(FormField.Merchant, merchant);
                setTimeout(() => setFieldTouched(FormField.Merchant, true, true));
            }}
        />
        <TextField
            {...getFormInputProps(FormField.Domain, form)}
            size='small'
            name={FormField.Domain}
            value={values[FormField.Domain]}
            label='Domain'
            onChange={handleChange}
            onBlur={handleBlur}

        />

    </Box>
);

export default memo(Whitelist);
