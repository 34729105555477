import { memo } from "react";
import { useRefetchFiltersResource } from "hooks";
import FiltersActionable from "ui/layouts/FiltersActionable";
import { ImportBlacklist } from "ui/organizms/Consumers/ConsumerActions";
// import RemoveFromBlacklist from './RemoveFromBlacklist';
// import Whitelist from './Whitelist';

const BlacklistedConsumersActions = () => {
    const refetch = useRefetchFiltersResource();

    const onSuccess = () => refetch();

    return (
        <FiltersActionable>
            <ImportBlacklist
                onSuccess={onSuccess}
            />
            {/* <RemoveFromBlacklist />
            <Whitelist /> */}
        </FiltersActionable>
    );
};

export default memo(BlacklistedConsumersActions);
