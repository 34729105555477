import { columnVisibilityQueryField, summsQueryField } from "consts/table";
import { compareIds, stringifyUrlQueryParams, when } from "./support";
import { Aggregates, RequestQueryMapperStrategy } from "consts/request-query";

type RequestQueryMapperSettings = {
    readonly setStrategy: RequestQueryMapperStrategy.Append | RequestQueryMapperStrategy.Set;
};

export class RequestQueryMapper {
    public forbiddenValues: unknown[] = [null];

    public static getInclusiveRangeQueryName(name: string, index = 0) {
        return `${name}[${this.getRangeToken(index)}]`;
    }

    public static applyWithCustomQueryAggregator<TKey extends string>({
        fontendQueryParams,
        backendQueryParams,
        queryExtensionMap
    }: Readonly<{
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams,
        queryExtensionMap: Map<TKey, (values: string[], requestQueryMapper: RequestQueryMapper) => void>
    }>) {
        let isApplied = false;

        queryExtensionMap.forEach((strategyHandler, filterId) => {
            if (fontendQueryParams.has(filterId)) {
                isApplied = true;
                strategyHandler(
                    fontendQueryParams.getAll(filterId),
                    this.from(backendQueryParams)
                );

                fontendQueryParams.delete(filterId);
            }
        });

        return isApplied;
    };

    private static getComputedQueryParams(searchQueryParams?: string | URLSearchParams): URLSearchParams {
        if (['string', 'undefined'].includes(typeof searchQueryParams)) {
            return new URLSearchParams(searchQueryParams);
        }

        return searchQueryParams as URLSearchParams;
    }

    private constructor(
        public searchQueryParams = new URLSearchParams(),
        public settings: RequestQueryMapperSettings = {
            setStrategy: RequestQueryMapperStrategy.Append
        }
    ) { }

    private get setStrategy() {
        return this.settings.setStrategy;
    }

    public static from(
        searchQueryParams?: URLSearchParams | string,
        settings?: RequestQueryMapperSettings
    ) {
        return new this(
            this.getComputedQueryParams(searchQueryParams),
            settings
        );
    }

    public checkWithModifier(name: string, value: string) {
        this.searchQueryParams[this.setStrategy](
            `${name}[o]`,
            value
        );

        return this;
    }

    public contains(name: string, value: string, multiple = false) {
        this.searchQueryParams[this.setStrategy](
            `${name}${multiple ? '[]' : ''}`,
            value
        );

        return this;
    }

    public containsIn(name: string, value: string) {
        this.searchQueryParams[this.setStrategy](
            `${name}[in][]`,
            value
        );

        return this;
    }

    public containsNotIn(name: string, value: string) {
        this.searchQueryParams[this.setStrategy](
            `${name}[notIn][]`,
            value
        );

        return this;
    }

    public distincts(name: string) {
        this.searchQueryParams[this.setStrategy](
            'distincts[]',
            name
        );

        return this;
    }

    public aggregates(strategy: Aggregates, field: string | [string, string]) {
        const value = [field].flat().at(-1);

        this.searchQueryParams[this.setStrategy](
            `aggregates[${strategy}]${
                when(Array.isArray(field), `[${field[0]}]`, '')
            }[]`,
            `${value}`
        );

        return this;
    }

    /**
     * Notes inclusiveRange doesn't support null values,
     * that is why we have to check for forbudden values
     */
    public inclusiveRange(name: string, value: string, index = 0) {
        if (!this.checkForbiddenValuesInvariant(value)) {
            this.searchQueryParams[this.setStrategy](
                RequestQueryMapper.getInclusiveRangeQueryName(name, index),
                value
            );
        }

        return this;
    }

    // sort[0][by]=consumerId&sort[0][dir]=DESC
    public sortBy(value: string, index = 0) {
        this.searchQueryParams[this.setStrategy](`sort[${index}][by]`, value);

        return this;
    }

    public sortOrder(value: string, index = 0) {
        this.searchQueryParams[this.setStrategy](`sort[${index}][dir]`, value);

        return this;
    }

    public sum(value: string) {
        this.searchQueryParams[this.setStrategy](summsQueryField, value);

        return this;
    }

    public toString() {
        return stringifyUrlQueryParams(this.searchQueryParams);
    }

    public relationsFilters(relationName: string, column: string, value: string) {
        this.searchQueryParams[this.setStrategy](
            `relationsFilters[${relationName}][${column}][in]`,
            value
        );

        return this;
    }

    public exportVisibility(value: string | string[]) {
        this.searchQueryParams[this.setStrategy](
            columnVisibilityQueryField,
            String(value)
        );

        return this;
    }

    public doesntHaveRelations(value: string) {
        this.searchQueryParams[this.setStrategy](
            `doesntHaveRelations[]`,
            value
        );

        return this;
    }

    public hasRelations(relationName: string, value: string, index?: number) {
        this.searchQueryParams[this.setStrategy](
            when(
                typeof index === 'number',
                `hasRelations[${relationName}][${RequestQueryMapper.getRangeToken(index!)}]`,
                `hasRelations[${relationName}]`
            ),
            value
        );

        return this;
    }

    public hasDistinctRelations(relationName: string, value: string, index?: number) {
        this.searchQueryParams[this.setStrategy](
            when(
                typeof index === 'number',
                `hasDistinctRelations[${relationName}][${RequestQueryMapper.getRangeToken(index!)}]`,
                `hasDistinctRelations[${relationName}]`
            ),
            value
        );

        return this;
    }


    public sqlDebug() {
        this.searchQueryParams[this.setStrategy](
            `sqlDebug`,
            '1'
        );

        return this;
    }

    public concat(searchQueryParams: string | URLSearchParams) {
        RequestQueryMapper.getComputedQueryParams(searchQueryParams).forEach((value, key) => {
            this.searchQueryParams[this.setStrategy](key, value);
        });

        return this;
    }

    public clone() {
        return new RequestQueryMapper(
            new URLSearchParams(this.searchQueryParams.toString()),
            this.settings
        );
    }

    private checkForbiddenValuesInvariant(value: string): boolean {
        return this.forbiddenValues.some(forbiddenValue => compareIds(forbiddenValue as string, value));
    }

    private static getRangeToken(index: number) {
        return index > 0
            ? 'to'
            : 'from';
    }
}
