import { memo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getFormInputProps } from "util/forms";
import { FormField, type CreateFormProps } from "./types";

const Create = ({
    sx,
    component,
    values,
    handleChange,
    handleBlur,
    ...form
}: CreateFormProps) => (
    <Box
        component={component}
        sx={{
            display: 'grid',
            gap: 2,
            minWidth: 475,
            ...sx
        }}
    >
        <TextField
            {...getFormInputProps(FormField.Email, form)}
            fullWidth
            required
            size='small'
            name={FormField.Email}
            value={values[FormField.Email]}
            label='Email'
            onChange={handleChange}
            onBlur={handleBlur}
        />
        <TextField
            {...getFormInputProps(FormField.FirstName, form)}
            fullWidth
            size='small'
            name={FormField.FirstName}
            value={values[FormField.FirstName]}
            label='First name'
            onChange={handleChange}
            onBlur={handleBlur}
        />
        <TextField
            {...getFormInputProps(FormField.LastName, form)}
            fullWidth
            size='small'
            name={FormField.LastName}
            value={values[FormField.LastName]}
            label='Last name'
            onChange={handleChange}
            onBlur={handleBlur}
        />
    </Box>
);

export default memo(Create);
