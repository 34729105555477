import { useMemo } from "react";
import { object, string } from "yup";
import { FormField } from "./types";

export default function useValidationSchema() {
    return useMemo(() => object().shape({
        [FormField.Email]: string()
            .required()
            .email()
            .max(255)
            .label('Email'),

        [FormField.FirstName]: string()
            .max(255)
            .label('First name'),

        [FormField.LastName]: string()
            .max(255)
            .label('Last name')
    }), []);
};
