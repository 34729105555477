import { memo, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { ProcessResponseStrategy, useSimpleRequest } from "hooks/useRequest";
import { downloadImportConsumerWhitelistTemplate } from "features/consumers/api";
import { NotificationSeverity } from "features/general/types";
import useActions from "features/general/useActions";

const ImportWhitelistTemplate = () => {
    const [isLoading, setLoading] = useState(false);

    const { showNotication } = useActions();

    const request = useSimpleRequest({
        setLoading,
        processResponseStrategy: ProcessResponseStrategy.Text
    });

    const displaySuccessfulNotification = () => showNotication({
        message: 'Template downloaded successfully',
        severity: NotificationSeverity.Success
    });

    const downloadTemplate = () => (
        request(downloadImportConsumerWhitelistTemplate, {
            onSuccess: displaySuccessfulNotification
        })
        .catch(error => {
            if (error instanceof TypeError) {
                displaySuccessfulNotification();
                return;
            }

            throw error;
        })
    );

    return (
        <LoadingButton
            variant='outlined'
            color='info'
            loading={isLoading}
            onClick={downloadTemplate}
        >
            Download Template
        </LoadingButton>
    );
};

export default memo(ImportWhitelistTemplate);
