import { useState, type MouseEvent } from "react";

export default function useBlacklist() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const isOpen = <
        TTruthyValue extends unknown,
        TFalsyValue extends unknown
    >(truthyValue?: TTruthyValue, falsyValue?: TFalsyValue) => (
        Boolean(anchorEl)
            ? truthyValue
            : falsyValue
    );

    const open = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const close = () => setAnchorEl(null);

    return {
        isOpen,
        anchorEl,
        open,
        close
    };
};
