import { memo } from "react";
import type { GridValueGetterParams } from "@mui/x-data-grid";
import { FLOAT_NUMBER_PRECISION } from "consts/general";
import { TableGridMode } from "consts/table";
import { Filters } from "consts/consumers";
import { fieldNumericPrecision } from "ui/widgets/Table/getters";
import ConsumersTable from "ui/organizms/Consumers/ConsumerTable";
import useActiveConsumers from "./useActiveConsumers";

const ActiveConsumersTable = () => {
    const {
        getActiveConsumers,
        downloadActiveConsumers
    } = useActiveConsumers();

    return (
        <ConsumersTable
            mode={TableGridMode.ActiveConsumer}
            fetchTableData={getActiveConsumers}
            downloadCsv={downloadActiveConsumers}
            columns={[
                {
                    field: Filters.rejectionRate,
                    headerName: 'Rejection rate',
                    description: 'The ratio of rejected transactions to total transactions (Number of rejected transactions / Number of total transactions)',
                    minWidth: 150,
                    align: 'center',
                    headerAlign: 'center',
                    valueGetter: ({ row, ...restProps }: GridValueGetterParams<unknown>) => fieldNumericPrecision({
                        precision: FLOAT_NUMBER_PRECISION
                    })({
                        ...restProps,
                        row,
                        value: (row.rejectedTransactions_count / row.parentTransactions_count) || 0
                    })
                }
            ]}
        />
    );
};

export default memo(ActiveConsumersTable);
