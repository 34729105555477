import { memo } from 'react';
import Tabs from 'ui/atoms/Tabs';
import { useStatefulTabs } from './hooks';
import type { StatefulTabsProps } from './types';

const StatefulTabs = (props: StatefulTabsProps) => {
    const {
        activeTab,
        onChange,
        onPointerDown
    } = useStatefulTabs(props);

    return (
        <Tabs
            {...props}
            value={activeTab}
            onChange={onChange}
            TabProps={{
                ...(props.TabProps ?? {}),
                onPointerDown
            }}
        />
    );
};

export default memo(StatefulTabs);
