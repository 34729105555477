import { memo } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { dialogActionsClasses } from "@mui/material/DialogActions";
import { Create } from "ui/forms/Consumer";
import { TriggerableDialog } from "ui/molecules/Dialog";
import ActionPanel from "ui/molecules/ActionPanel";
import useAddConsumer from "./useAddConsumer";
import type { AddConsumerProps } from "./types";

const AddConsumer = (props: AddConsumerProps) => {
    const addConsumerForm = Create.useForm({
        onSaveOrCreate: useAddConsumer(props),
        initialValues: Create.getInitialValues(),
        isBootstrapDataLoading: false,
        bootstrapData: {}
    });

    const {
        isValid,
        isSubmitting,
        submitForm,
        resetForm
    } = addConsumerForm;

    const withReset = (fn: () => void) => () => {
        fn();
        setTimeout(resetForm);
    };

    return (
        <TriggerableDialog
            TriggerComponent={({ onClick }) => (
                <LoadingButton
                    variant='soft'
                    onClick={onClick}
                    loading={isSubmitting}
                >
                    Add consumer
                </LoadingButton>
            )}
            DialogActionsComponent={({ onClose }) => (
                <ActionPanel
                    sx={{
                        border: 'none',
                        p: 0,
                        m: 0
                    }}
                    CancelActionProps={{
                        disabled: isSubmitting
                    }}
                    SaveActionProps={{
                        children: 'Add consumer',
                        disabled: !isValid,
                        loading: isSubmitting
                    }}
                    onCancel={withReset(onClose)}
                    onSave={() => (
                        submitForm()
                            .then(onClose)
                    )}
                />
            )}
            ModalProps={{
                titleSlot: 'Add consumer',
                onClose: withReset(() => {}),
                disableCloseOnBackdropClick: true,
                DialogProps: {
                    sx: {
                        [`& .${dialogActionsClasses.root}`]: {
                            justifyContent: 'center'
                        }
                    }
                }
            }}
        >
            <Create.Form
                {...addConsumerForm}
            />
        </TriggerableDialog>
    );
};

export default memo(AddConsumer);
