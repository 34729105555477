import { useQuery, useFetch } from "hooks/useQuery";
import { Filters } from "consts/transactions";
import { Aggregates } from "consts/request-query";
import type { ConsumersResponse } from "features/consumers/types";
import { getEsTransactions } from "features/transactions/api";
import { getConsumers } from "features/consumers/api";
import type {
    EsTopMerchantSuceededTransactionsByUniqueEmailResponse,
    EsTransactionsByCompanyPerPSPResponse,
    EsTransactionsByCurrencyResponse,
    EsTransactionsByStatusResponse
} from "./types";
import {
    totalCountRequestQueryParamsFactory,
    transactionsRequestQueryParamsFactory
} from "./requestQueryParamsFactory";
import { RequestQueryMapper } from "util/request-query-mapper";

export const esTransactionsGroupByCurrencyQueryToken = Symbol('esTransactionsGroupByCurrencyQueryToken::CURRENCY');
export const esTransactionsGroupByStatusQueryToken = Symbol('esTransactionsGroupByStatusQueryToken::STATUS');
export const esTransactionsByCompanyPerPSPQueryToken = Symbol('esTransactionsByCompanyPerPSPQueryToken');
export const totalConsumersQueryToken = Symbol('totalConsumersQueryToken');
export const topMerchantSucceededTransactionsByUniqueEmailQueryToken = Symbol('topMerchantSucceededTransactionsByUniqueEmailQueryToken');

export default function useStatisticDashboardQuery() {
    const esTransactionsByCurrencyQuery = useQuery<EsTransactionsByCurrencyResponse>({
        getRequestQueryParams: requestQueryMapper =>
            transactionsRequestQueryParamsFactory(Filters.currency)(
                requestQueryMapper
                    .containsIn(Filters.status, 'SUCCEEDED')
            ),
        fetchQuery: getEsTransactions
    });

    const esTransactionsByStatusQuery = useQuery<EsTransactionsByStatusResponse>({
        getRequestQueryParams: transactionsRequestQueryParamsFactory(Filters.status),
        fetchQuery: getEsTransactions
    });

    const esTransactionsByCompanyPerPSPQuery = useQuery<EsTransactionsByCompanyPerPSPResponse>({
        getRequestQueryParams: requestQueryMapper => (
            requestQueryMapper
                .containsIn(Filters.status, 'SUCCEEDED')
                .aggregates(Aggregates.GroupBys, Filters.MOR)
                .aggregates(Aggregates.GroupBys, Filters.PSP)
                .aggregates(Aggregates.Subtotals, Filters.amountEur)
                .aggregates(Aggregates.Subavgs, '')
        ),
        fetchQuery: getEsTransactions
    });

    const totalConsumersQuery = useQuery<ConsumersResponse>({
        getRequestQueryParams: () => totalCountRequestQueryParamsFactory()(
            RequestQueryMapper.from()
        ),
        fetchQuery: getConsumers
    });

    const topMerchantSucceededTransactionsByUniqueEmailQuery = useQuery<
        EsTopMerchantSuceededTransactionsByUniqueEmailResponse
    >({
        getRequestQueryParams: requestQueryMapper => totalCountRequestQueryParamsFactory(5)(
            requestQueryMapper
                .containsIn(Filters.status, 'SUCCEEDED')
                .aggregates(Aggregates.GroupBys, Filters.merchantId)
                .aggregates(Aggregates.Subtotals, Filters.amountEur)
                .aggregates(Aggregates.Subavgs, '')
                .sortBy(Filters.amountEur_total)
        ),
        fetchQuery: getEsTransactions
    });

    return {
        ...useFetch(
            esTransactionsByCurrencyQuery,
            esTransactionsByStatusQuery,
            esTransactionsByCompanyPerPSPQuery,
            totalConsumersQuery,
            topMerchantSucceededTransactionsByUniqueEmailQuery
        ),
        [esTransactionsGroupByCurrencyQueryToken]: esTransactionsByCurrencyQuery,
        [esTransactionsGroupByStatusQueryToken]: esTransactionsByStatusQuery,
        [esTransactionsByCompanyPerPSPQueryToken]: esTransactionsByCompanyPerPSPQuery,
        [totalConsumersQueryToken]: totalConsumersQuery,
        [topMerchantSucceededTransactionsByUniqueEmailQueryToken]: topMerchantSucceededTransactionsByUniqueEmailQuery
    };
};
