import { memo } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { TabIndex, TabProps } from "ui/organizms/Consumers/ConsumerDialogs/MultiTabDialog/types";

const IbanList = ({
    tabRegistry: [
        dialogDataRegistry
    ],
    dialogRequestProcessing: [
        isLoading
    ]
}: TabProps<TabIndex.MoreDetails>) => {
    const getIbans = () => dialogDataRegistry.transactionOrigins.map(({ iban }) => iban);

    const renderIbanList = () => {
        const ibanList = getIbans();

        if (ibanList.length > 0) {
            return (
                <>
                    <Box
                        sx={{
                            fontSize: 14,
                            fontWeight: 700
                        }}
                    >
                        Iban List
                    </Box>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'min-content 1fr',
                            mb: 2
                        }}
                    >
                        {ibanList.map(iban => (
                            <Box
                                key={iban}
                            >
                                {iban}
                            </Box>
                        ))}
                    </Box>
                </>
            );
        }

        return (
            <Box
                sx={{
                    fontSize: 14,
                    fontWeight: 700,
                    color: 'text.disabled'
                }}
            >
                No ibans
            </Box>
        );
    };

    const renderLoader = () => (
        <Box
            sx={{
                display: 'grid',
                placeContent: 'center',
                mb: 2
            }}
        >
            <CircularProgress />
        </Box>
    );

    return (
        <Box>
            {isLoading
                ? renderLoader()
                : renderIbanList()}
        </Box>
    );
};

export default memo(IbanList);
