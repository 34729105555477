import { memo } from "react";
import type { ResourceResponse } from "types";
import { TableGridMode } from "consts/table";
import type { Consumer } from "features/consumers/types";
import { useConsumersActions } from "features/consumers/actions";
import Resource from 'ui/layouts/Resource';
import ConsumerActions from "ui/organizms/Consumers/ConsumerActions";
import ConsumersTable from "ui/organizms/Consumers/ConsumerTable";

const AllConsumers = () => {
    const { addConsumer } = useConsumersActions();

    const onAdd = ({ data }: ResourceResponse<Consumer>) => addConsumer(data);

    return (
        <Resource
            breadcrumbsProps={{
                heading: 'All Consumers',
                links: [{ name: '' }],
                action: (
                    <ConsumerActions
                        onAdd={onAdd}
                    />
                )
            }}
        >
            <ConsumersTable
                mode={TableGridMode.Consumer}
            />
        </Resource>
    );
};

export default memo(AllConsumers);
