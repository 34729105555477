import { memo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ApiRoutes } from "consts/enpoints/api";
import { MaxFileSizeBytes } from "consts/consumers";
import useActions from "features/general/useActions";
import { NotificationSeverity } from "features/general/types";
import { Whitelist } from "ui/forms/Consumer";
import FileUploader, { type FileUploaderProps } from "ui/widgets/FileUploader";
import ImportWhitelistTemplate from "./ImportWhitelistTemplate";

type Props = Pick<
    FileUploaderProps,
    | 'TriggerComponent'
    | 'TriggerComponentProps'
>;

const ImportWhitelist = (props: Props) => {
    const { showNotication } = useActions();
    const whitelistForm = Whitelist.useForm();

    const {
        isValid,
        values,
        resetForm
    } = whitelistForm;

    const onSuccess = () => {
        showNotication({
            message: 'Whitelist uploaded successfully. It will be processed at the next scheduled time.',
            severity: NotificationSeverity.Success
        });
        resetForm();
    };

    const supportedFormatsMessage = (
        <Typography
            sx={{
                fontFamily: 'var(--primary-font)',
                fontSize: 12,
                color: 'grey.500',
                fontWeight: 600,
                textDecoration: 'underline'
            }}
            gutterBottom
        >
            Supported formats: .csv, UTF-8 encoded, comma-separated
        </Typography>
    );

    return (
        <FileUploader
            url={ApiRoutes.PostImportConsumerWhitelist}
            onSuccess={onSuccess}
            getBody={() => ({
                [Whitelist.FormField.MerchantId]: values[Whitelist.FormField.Merchant]?.id,
                [Whitelist.FormField.Domain]: values[Whitelist.FormField.Domain]

            })}
            ModalProps={{
                onClose: () => resetForm(),
                disableCloseOnBackdropClick: true,
                titleSlot: 'Upload Whitelist(s) CSV'
            }}
            FileDropAreaProps={{
                children: supportedFormatsMessage,
                maxSize: MaxFileSizeBytes.WhiteList
            }}
            UploadButtonProps={{
                disabled: !isValid
            }}
            after={(
                <Whitelist.Form
                    {...whitelistForm}
                    sx={{
                        mt: 2
                    }}
                />
            )}
            {...props}
            TriggerComponentProps={{
                children: (
                    <>
                        Import Whitelist (CSV)
                    </>
                ),
                ...props.TriggerComponentProps
            }}
        >
            <Box
                sx={{
                    ml: 1,
                    mb: 2
                }}
            >
                {supportedFormatsMessage}
                <ImportWhitelistTemplate />
            </Box>
        </FileUploader>
    );
};

export default memo(ImportWhitelist);