import type { FormikHelpers } from "formik";
import type { ResourceResponse } from "types";
import { useAppStore } from "hooks";
import { useSimpleRequest } from "hooks/useRequest";
import { executeOnSuccess } from "util/api";
import type { Consumer } from "features/consumers/types";
import { postConsumer } from "features/consumers/api";
import { FormAdapter } from "hooks/useForm";
import type { AddConsumerProps } from "./types";

export default function useAddConsumer({
    onAdd = _ => {}
}: AddConsumerProps) {
    const store = useAppStore();
    const request = useSimpleRequest();

    return async ({ email, firstName, lastName, active }: Partial<Consumer>, formikHelpers?: FormikHelpers<Partial<Consumer>>) => {
        const response = await request(() => postConsumer({
            hashedEmail: email,
            firstName,
            lastName,
            active: active,
            MOR: store.getState().general.domains.MOR
        }));

        executeOnSuccess(
            response,
            (response: ResourceResponse<Consumer>) => {
                formikHelpers?.resetForm();
                onAdd(response);
            }
        );

        return FormAdapter.fromResponse(response);
    };
};
