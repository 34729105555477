import { memo } from 'react';
import Box from "@mui/material/Box";
import Skeleton from '@mui/material/Skeleton';
import StatisticCard from './StatisticCard';
import useTransactionStats from './useTransactionStats';
import { TabIndex, TabProps } from "ui/organizms/Consumers/ConsumerDialogs/MultiTabDialog/types";

const TransactionStats = (props: TabProps<TabIndex.MoreDetails>) => {
    const { dialogRequestProcessing: [isLoading] } = props;
    const getTransactionStats = useTransactionStats(props);

    const renderLoader = () => (
        <>
            {Array.from({ length: 5 })
                .fill(true)
                .map((_, index) => (
                    <Skeleton
                        key={index}
                        height={102}
                        width='100%'
                    />
                ))}
        </>
    );

    const renderTransactionStats = () =>
        Array.from(getTransactionStats().entries())
            .filter(([, transactionStats]) => transactionStats.value)
            .map(([transactionStatsUnit, transactionStats]) => (
                <StatisticCard
                    key={transactionStatsUnit}
                    {...transactionStats}
                />
            ));

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 2
            }}
        >
            {isLoading
                ? renderLoader()
                : renderTransactionStats()}
        </Box>
    );
};

export default memo(TransactionStats);
