import type { ResourceResponse } from "types";
import { useSimpleRequest } from "hooks/useRequest";
import { getPromiseSettledResourceResult } from "util/resource";
import { executeOnSuccess } from "util/api";
import { BooleanSwitchValue } from "consts/general";
import useActions from "features/general/useActions";
import { NotificationSeverity } from "features/general/types";
import { useConsumersActions } from "features/consumers/actions";
import { createConsumersBlacklist, updateConsumer } from "features/consumers/api";
import type { Consumer } from "features/consumers/types";
import type { BlacklistForm } from "ui/forms/Consumer/Blacklist";

export default function useBlacklistSelected({ values }: BlacklistForm) {
    const request = useSimpleRequest();

    const { showNotication } = useActions();

    const { updateConsumerById } = useConsumersActions();

    return async (consumers: Array<Consumer>) => {
        for (const {
            coreId,
            email,
            hashedEmail,
            firstName,
            lastName
        } of consumers) {
            const [consumerSettledResponse] = await Promise.allSettled([
                request(() => updateConsumer({
                    coreId,
                    active: BooleanSwitchValue.Off
                })),
                request(() => createConsumersBlacklist({
                    email,
                    hashedEmail,
                    firstName,
                    lastName,
                    asPerson: values.asPerson,
                    reason: String(
                        values.otherReason ||
                        values.reason
                    ).trim()
                }))
            ]);

            executeOnSuccess(
                getPromiseSettledResourceResult(consumerSettledResponse),
                ({ data }: ResourceResponse<Consumer>) => updateConsumerById(data)
            );
        }

        showNotication({
            message: 'Selected consumers have been blacklisted',
            severity: NotificationSeverity.Success
        });
    };
};
