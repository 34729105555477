import { resourceSliceFactory } from "infrastructure/store/state";
import type { ConsumersState } from "./types";
import { MenuItem } from "consts/consumers";

const state: ConsumersState = {
    [MenuItem.All]: resourceSliceFactory(),
    [MenuItem.Blacklisted]: resourceSliceFactory(),
    [MenuItem.WhiteListed]: resourceSliceFactory()
};

export const sliceToken = 'consumers';

export default state;
