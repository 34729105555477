import { useFormik, type FormikConfig } from "formik";
import { FormField, type Whitelist } from "./types";
import useValidationSchema from "./useValidationSchema";

export const getInitialValues = (initialValues: Partial<Whitelist> = {}) => ({
    [FormField.Merchant]: null,
    [FormField.Domain]: '',
   ...initialValues
});

export const useForm = (whitelistForm?: FormikConfig<Whitelist>) => {
    const {
        initialValues,
        ...restFormArgs
    } = whitelistForm ?? {};

    return useFormik({
        enableReinitialize: true,
        validateOnMount: true,
        isInitialValid: false,
        ...restFormArgs,
        onSubmit: () => {},
        validationSchema: useValidationSchema(),
        initialValues: getInitialValues(initialValues)
    });
};
