import { memo } from "react";
import type { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import {
    type GridRowParams,
    GRID_ACTIONS_COLUMN_TYPE,
    GRID_CHECKBOX_SELECTION_FIELD,
} from "@mui/x-data-grid-premium";
import { MenuItem } from "consts/consumers";
import useResetState from "hooks/useResetState";
import {
    selectConsumersSlice,
    selectIsConsumersLoading,
    selectIsConsumersUninitialized
} from "features/consumers/selectors";
import { resetConsumersState } from "features/consumers/slice";
import Table from "ui/widgets/Table";
import * as tableComponents from "./components";
import { useConsumerTable, useRequestQueryOverrideDecorator } from "./hooks";
import type { ConsumerTableProps } from "./types";

const { TableAction } = tableComponents;

const ConsumerTable = ({ columns = [], ...restProps }: ConsumerTableProps) => {
    const {
        isRowSelectable,
        getConsumers,
        downloadCsv,
        filterTypes,
        CellProps,
        FiltersProps,
        selectionModel,
        setSelectionModel
    } = useConsumerTable();

    const getInitialState: () => GridInitialStatePremium = () => ({
        pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_FIELD],
            right: [GRID_ACTIONS_COLUMN_TYPE]
        }
    });

    return (
        <Table
            fetchTableData={getConsumers}
            downloadCsv={downloadCsv}
            {...restProps}
            disableSelectionOnClick
            checkboxSelection
            resetState={useResetState(resetConsumersState)}
            isRowSelectable={isRowSelectable}
            filterTypes={filterTypes}
            selectIsTableUninitialized={selectIsConsumersUninitialized(MenuItem.All)}
            selectIsTableLoading={selectIsConsumersLoading(MenuItem.All)}
            selectTableDataSlice={selectConsumersSlice(MenuItem.All)}
            selectionModel={selectionModel}
            onSelectionModelChange={setSelectionModel}
            initialState={getInitialState()}
            columns={[
                ...columns,
                {
                    field: GRID_ACTIONS_COLUMN_TYPE,
                    type: GRID_ACTIONS_COLUMN_TYPE,
                    width: 50,
                    maxWidth: 50,
                    getActions: (rowParams: GridRowParams) => [
                        <TableAction {...rowParams} />
                    ]
                }
            ]}
            components={tableComponents}
            CellProps={CellProps}
            requestQueryOverrideDecorator={useRequestQueryOverrideDecorator}
            pinnedColumnsSx={{
                right: {
                    width: 50
                }
            }}
            LayoutProps={{
                sx: {
                    mx: 0
                }
            }}
            FiltersProps={{
                ...FiltersProps,
                sx: {
                    mx: 0
                },
                isDefaultFiltersOpen: true
            }}
        />
    );
};

export default memo(ConsumerTable);
