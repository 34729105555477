import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { StatefulTabs, type StatefulTabsProps } from "../types";

export default function useStatefulTabs({
    value = StatefulTabs.InitialValue,
    searchParamName = StatefulTabs.SearchParamName,
    ...restProps
}: StatefulTabsProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const propsRef = useRef({
        value,
        searchParamName,
        setSearchParams
    });

    const [activeTab, setActiveTab] = useState(() => searchParams.get(searchParamName) ?? value);

    useEffect(() => {
        const {
            value,
            searchParamName,
            setSearchParams
        } = propsRef.current;

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(
            searchParamName,
            searchParams.get(searchParamName) ?? value
        );

        setSearchParams(searchParams);
    }, []);

    const onChange: StatefulTabsProps['onChange'] = (value, changeTab) => {
        searchParams.set(searchParamName, value);

        setSearchParams(searchParams);
        setActiveTab(value);
        changeTab!(value);
        restProps.onChange?.(value);
    };

    const onPointerDown = () => setSearchParams();

    return {
        activeTab,
        onChange,
        onPointerDown
    };
};
