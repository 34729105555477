import { useEffect, useState, type ChangeEvent } from "react";
import Switch, { type SwitchProps } from '@mui/material/Switch';
import CircularProgress, { type CircularProgressProps } from "@mui/material/CircularProgress";
import type { TooltipProps } from "@mui/material/Tooltip";
import withGridCellParams from "./withGridCellParams";
import { useCellContext } from "../contexts/CellProvider";
import Tooltip from "@mui/material/Tooltip";

export type SimpleSwitchCellProps = SwitchProps & {
    readonly loading?: boolean;
    readonly CircularProgressProps?: CircularProgressProps;
    readonly TooltipProps?: TooltipProps;
};

const SimpleSwitch = withGridCellParams<boolean>(({ value, field, ...restProps }) => {
    const [isActive, setActiveState] = useState(Boolean(value));

    useEffect(() => {
        setActiveState(Boolean(value));
    }, [value]);

    const {
        [field]: {
            onChange,
            getCellProps
        }
    } = useCellContext();

    const handleSwitchChange: SwitchProps['onChange'] = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setActiveState(checked);
        onChange?.(
            { ...restProps, value, field },
            event,
            checked
        );
    };

    const {
        loading,
        CircularProgressProps = { size: 20 },
        TooltipProps = {},
        ...switchProps
    } = (getCellProps?.<SimpleSwitchCellProps>({ ...restProps, value, field }) ?? {});

    if (loading) {
        return (
            <CircularProgress
                {...CircularProgressProps}
            />
        );
    }

    return (
        <Tooltip
            arrow
            placement='top'
            title=''
            {...TooltipProps}
        >
            <Switch
                {...switchProps}
                checked={isActive}
                onChange={handleSwitchChange}
            />
        </Tooltip>
    );
});

export default SimpleSwitch;
