import { memo } from "react";
import { MenuItem } from "consts/consumers";
import type { BlacklistedConsumer } from "features/consumers/types";
import { Action } from "ui/organizms/Consumers/ConsumerActions";
import useRemoveFromBlacklist from "./useRemoveFromBlacklist";

const RemoveFromBlacklist = () => (
    <Action
        <BlacklistedConsumer>
        group={MenuItem.Blacklisted}
        TriggerComponentProps={{
            children: 'Remove from blacklist'
        }}
        onConfirm={useRemoveFromBlacklist()}
    >
        By confirming this action - the user(s) will be removed from the blacklist.
        If you want the user(s) to be able to process as well, please use the 'Whitelist' functionality
    </Action>
);

export default memo(RemoveFromBlacklist);
