import { useRequest } from "hooks";
import { ApiRouteTypes } from "consts/enpoints/api";
import useExportTable from "hooks/useExportTable";
import type { ThunkReturnType } from "types";
import { changeBlacklistedConsumersExportLoading as changeExportLoading } from "../slice";
import type { WhitelistedConsumerResponse } from "../types";
import {  getWhitelistedConsumersThunk } from "../thunks";

const useWhitelistedConsumersActions = () => {
    const getConsumers = useRequest<ThunkReturnType<WhitelistedConsumerResponse>, string>({
        thunk: getWhitelistedConsumersThunk
    });

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetConsumersWhitelist
    });



    return {
        getConsumers,
        downloadCsv,
    };
};

export default useWhitelistedConsumersActions;
