import type { BlacklistedEmailEntity, Consumer } from "features/consumers/types";
import type { ManageDuplicatesDataRegistry } from "ui/organizms/Consumers/ConsumerDialogs/MultiTabDialog/types";

export default class DuplicatedEmailRegistryEntry implements ManageDuplicatesDataRegistry {
    constructor(consumer: Consumer);
    constructor(consumer: Consumer, blacklistedEmailEntity: BlacklistedEmailEntity);

    constructor(
        public readonly consumer: Consumer,
        public readonly blacklistedEmailEntity: BlacklistedEmailEntity | null = null
    ) { }

    public get id() {
        return this.consumer.coreId;
    }

    public get isBlacklisted() {
        return Boolean(this.blacklistedEmailEntity?.blocked);
    }

    public get email() {
        return this.consumer.email;
    }

    public get hashedEmail() {
        return this.consumer.hashedEmail;
    }

    public get computedEmail() {
        return this.consumer.email || this.consumer.hashedEmail || '';
    }

    public whitelist() {
        return this.makeBlockedFromExistingInstance(false);
    }

    public blacklist() {
        return this.makeBlockedFromExistingInstance(true);
    }

    private makeBlockedFromExistingInstance(blocked: boolean) {
        return new DuplicatedEmailRegistryEntry(
            this.consumer,
            {
                ...this.blacklistedEmailEntity,
                blocked,
            } as BlacklistedEmailEntity
        );
    }
}
