import { memo } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { useRefetchFiltersResource } from "hooks";
import BlacklistCSV from "./BlacklistCSV";
import BlacklistSelected from "./BlacklistSelected";
import useBlacklist from "./useBlacklist";

const Blacklist = () => {
    const refetch = useRefetchFiltersResource();
    const {
        isOpen,
        anchorEl,
        open,
        close
    } = useBlacklist();

    const onSuccess = () => {
        close();
        refetch();
    };

    return (
        <>
            <Button
                id='blacklist-trigger'
                sx={{
                    bgcolor: 'black',
                    ':hover': {
                        bgcolor: 'black'
                    }
                }}
                aria-controls={isOpen('blacklist-trigger-menu')}
                aria-haspopup='true'
                aria-expanded={isOpen('true')}
                variant='contained'
                disableElevation
                onClick={open}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Blacklist
            </Button>
            <Menu
                id='blacklist-menu'
                anchorEl={anchorEl}
                open={Boolean(isOpen(true, false))}
                onClose={close}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    'aria-labelledby': 'blacklist-trigger',
                    dense: true
                }}
            >
                <BlacklistSelected
                    onSuccess={onSuccess}
                />
                <Divider />
                <BlacklistCSV
                    onSuccess={onSuccess}
                />
            </Menu>
        </>
    );
};

export default memo(Blacklist);
