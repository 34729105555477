import { memo } from 'react';
import Box from '@mui/material/Box';
import { AddDuplicateEmail as AddDuplicateEmailFrom } from 'ui/forms/Consumer';
import { TabIndex, TabProps } from 'ui/organizms/Consumers/ConsumerDialogs/MultiTabDialog/types';
import type { Consumer } from 'features/consumers/types';
import useAddDuplicateEmail from './useAddDuplicateEmail';
import { SamePersonIdentifierSource } from 'consts/consumers';

type Props = TabProps<TabIndex.ManageDuplicates> & {
    readonly isRequestLoading: boolean;
};

const AddDuplicateEmail = (props: Props) => {
    const {
        addDuplicateEmailFormRef,
        onSubmit
    } = useAddDuplicateEmail(props);

    const {
        getTabPayload,
        isRequestLoading
    } = props;

    if (isRequestLoading) {
        return null;
    }

    return (
        <Box>
            <AddDuplicateEmailFrom.Form
                ref={addDuplicateEmailFormRef}
                onSaveOrCreate={onSubmit}
                actionLabel='Add another email'
                initialValues={{
                    samePersonIdentifierSource: getTabPayload<Consumer>()
                        .samePersonIdentifierSource
                        .replace(`${SamePersonIdentifierSource.Manual}:`, '')
                        .trim()
                }}
            />
        </Box>
    );
};

export default memo(AddDuplicateEmail);
