import { memo } from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { ImportBlacklist } from "ui/organizms/Consumers/ConsumerActions";
import type { BlacklistMenuItemProps } from "../types";

const BlacklistCSV = ({ onSuccess }: BlacklistMenuItemProps) => (
    <ImportBlacklist
        onSuccess={onSuccess}
        TriggerComponent={({ onClick }) => (
            <MenuItem
                component='button'
                onClick={onClick}
            >
                <ListItemIcon>
                    <UploadFileIcon />
                </ListItemIcon>
                <ListItemText>Blacklist via CSV</ListItemText>
            </MenuItem>
        )}
    />
);

export default memo(BlacklistCSV);
