import type { BoxProps } from "@mui/material/Box";
import type { useFormik } from "formik";
import type { Option } from "types";

export const enum FormField {
    Merchant = 'merchant',
    Domain = 'domain',
    MerchantId = 'merchantId'
};

export type Whitelist = {
    readonly [FormField.Merchant]: Option | null;
    readonly [FormField.Domain]?: string;
};

export type WhitelistProps =
    & BoxProps
    & ReturnType<typeof useFormik<Whitelist>>;
