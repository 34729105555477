import { memo } from "react";
import Resource from "ui/layouts/Resource";
import ConsumerActions from "ui/organizms/Consumers/ConsumerActions";
import ActiveConsumersTable from "./ActiveConsumersTable";

const ActiveConsumers = () => (
    <Resource
        breadcrumbsProps={{
            heading: 'Active Consumers',
            links: [{ name: '' }],
            action: (
                <ConsumerActions />
            )
        }}
    >
        <ActiveConsumersTable />
    </Resource>
);

export default memo(ActiveConsumers);
