import { memo, useCallback } from "react";
import { TableGridMode } from "consts/table";
import { MenuItem } from "consts/consumers";
import useResetState from "hooks/useResetState";
import { resetBlacklistedConsumersState as resetState } from "features/consumers/slice";
import {
    selectConsumersSlice,
    selectIsConsumersLoading,
    selectIsConsumersUninitialized
} from "features/consumers/selectors";
import Table from "ui/widgets/Table";
import * as tableComponents from "ui/organizms/Consumers/ConsumerTable";
import useBlacklistedConsumersTable from "./useBlacklistedConsumersTable";

import { Box } from "@mui/material";
import RemoveFromBlacklist from '../BlacklistedConsumersActions/RemoveFromBlacklist';
import Whitelist from '../BlacklistedConsumersActions/Whitelist';

const { Toolbar, ...restTableComponent } = tableComponents;

const BlacklistedConsumersTable = () => {
    const {
        isRowSelectable,
        filterTypes,
        selectionModel,
        setSelectionModel,
        getConsumers,
        downloadCsv
    } = useBlacklistedConsumersTable();

    const BlacklistedConsumersTableToolbar = useCallback((props: tableComponents.ToolbarProps) => (
        <Toolbar
            {...props}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Whitelist />
                <RemoveFromBlacklist />
            </Box>
        </Toolbar>
    ), []);

    return (
        <Table
            disableSelectionOnClick
            checkboxSelection
            mode={TableGridMode.BlacklistedConsumer}
            resetState={useResetState(resetState)}
            isRowSelectable={isRowSelectable}
            fetchTableData={getConsumers}
            filterTypes={filterTypes}
            selectIsTableUninitialized={selectIsConsumersUninitialized(MenuItem.Blacklisted)}
            selectIsTableLoading={selectIsConsumersLoading(MenuItem.Blacklisted)}
            selectTableDataSlice={selectConsumersSlice(MenuItem.Blacklisted)}
            downloadCsv={downloadCsv}
            selectionModel={selectionModel}
            onSelectionModelChange={setSelectionModel}
            components={restTableComponent}
            Toolbar={BlacklistedConsumersTableToolbar}
            LayoutProps={{
                sx: {
                    mx: 0
                }
            }}
            FiltersProps={{
                sx: {
                    mx: 0
                },
                isDefaultFiltersOpen: true
            }}
        />
    );
};

export default memo(BlacklistedConsumersTable);
