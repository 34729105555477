import useFormHook from "hooks/useForm";
import { FormField, type Create, type CreateForm } from "./types";
import useValidationSchema from "./useValidationSchema";
import { BooleanSwitch } from "types";

export const getInitialValues = (initialValues: Partial<Create> = {}) => ({
    [FormField.Email]: '',
    [FormField.FirstName]: '',
    [FormField.LastName]: '',
    [FormField.Active]: 1 as BooleanSwitch,
   ...initialValues
});

export const useForm = (createForm: CreateForm) => {
    const {
        initialValues,
        ...restFormArgs
    } = createForm;

    return useFormHook({
        enableReinitialize: true,
        isInitialValid: false,
        validateOnChange: true,
        ...restFormArgs,
        validationSchema: useValidationSchema(),
        initialValues: getInitialValues(initialValues)
    });
};
