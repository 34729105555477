import type { BoxProps } from "@mui/material/Box";
import type { useFormik } from "formik";
import type { BooleanSwitch } from "types";

export const enum FormField {
    Reason = 'reason',
    AsPerson = 'asPerson',
    OtherReason = 'otherReason'
};

export type Blacklist = {
    readonly [FormField.Reason]: string | null;
    readonly [FormField.AsPerson]: BooleanSwitch;
    readonly [FormField.OtherReason]?: string;
};

export type BlacklistForm = ReturnType<typeof useFormik<Blacklist>>;

export type BlacklistProps =
    & BoxProps
    & BlacklistForm;
