import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer/dist/internal";
import { MenuItem } from "consts/consumers";
import initialState, { sliceToken } from "./state";
import {
    deleteConsumersBlacklistThunk,
    getBlacklistedConsumersThunk,
    getConsumersThunk,
    whitelistConsumersThunk,
    getWhitelistedConsumersThunk,
    postConsumerThunk
} from "./thunks";
import type { Consumer, ConsumersState } from "./types";
import {
    consumersRequestFulfilled,
    consumersRequestLoading,
    consumersRequestRejected,
    updateConsumerEntity,
    resetState,
    changeExportLoading,
    deleteConsumersBlacklistRequestFulfilled,
    postConsumerRequestFulfilled,
    addConsumerEntity
} from "./reducers";

const slice = createSlice({
    name: sliceToken,
    initialState,
    reducers: {
        resetConsumersState: resetState(MenuItem.All),
        resetBlacklistedConsumersState: resetState(MenuItem.Blacklisted),
        resetWhitelistedConsumersState: resetState(MenuItem.WhiteListed),
        changeConsumersExportLoading: changeExportLoading(MenuItem.All),
        changeWhitelistedConsumersExportLoading: changeExportLoading(MenuItem.WhiteListed),
        changeBlacklistedConsumersExportLoading: changeExportLoading(MenuItem.Blacklisted),
        addConsumersEntity: (state: WritableDraft<ConsumersState>, { payload }: PayloadAction<Consumer>) =>
            addConsumerEntity(MenuItem.All)(state, payload),
        updateConsumersEntity: (state: WritableDraft<ConsumersState>, { payload }: PayloadAction<Partial<Consumer>>) =>
            updateConsumerEntity(MenuItem.All)(state, payload),
    },
    extraReducers: builder => {
        builder
            // Get consumers
            .addCase(getConsumersThunk.pending, consumersRequestLoading(MenuItem.All))
            .addCase(getConsumersThunk.fulfilled, consumersRequestFulfilled(MenuItem.All))
            .addCase(getConsumersThunk.rejected, consumersRequestRejected(MenuItem.All))
            // Add consumer
            .addCase(postConsumerThunk.pending, consumersRequestLoading(MenuItem.All))
            .addCase(postConsumerThunk.fulfilled, postConsumerRequestFulfilled)
            .addCase(postConsumerThunk.rejected, consumersRequestRejected(MenuItem.All))
            // Get blacklisted consumers
            .addCase(getBlacklistedConsumersThunk.pending, consumersRequestLoading(MenuItem.Blacklisted))
            .addCase(getBlacklistedConsumersThunk.fulfilled, consumersRequestFulfilled(MenuItem.Blacklisted))
            .addCase(getBlacklistedConsumersThunk.rejected, consumersRequestRejected(MenuItem.Blacklisted))
            // Remove from blacklist
            .addCase(deleteConsumersBlacklistThunk.pending, consumersRequestLoading(MenuItem.Blacklisted))
            .addCase(deleteConsumersBlacklistThunk.fulfilled, deleteConsumersBlacklistRequestFulfilled)
            .addCase(deleteConsumersBlacklistThunk.rejected, consumersRequestRejected(MenuItem.Blacklisted))
            // Whitelist consumers
            .addCase(whitelistConsumersThunk.pending, consumersRequestLoading(MenuItem.Blacklisted))
            .addCase(whitelistConsumersThunk.fulfilled, deleteConsumersBlacklistRequestFulfilled)
            .addCase(whitelistConsumersThunk.rejected, consumersRequestRejected(MenuItem.Blacklisted))
            //whitelisted consumers
            .addCase(getWhitelistedConsumersThunk.pending, consumersRequestLoading(MenuItem.WhiteListed))
            .addCase(getWhitelistedConsumersThunk.fulfilled, consumersRequestFulfilled(MenuItem.WhiteListed))
            .addCase(getWhitelistedConsumersThunk.rejected, consumersRequestRejected(MenuItem.WhiteListed))
    }
});

export const {
    name,
    actions: {
        changeConsumersExportLoading,
        changeBlacklistedConsumersExportLoading,
        resetConsumersState,
        resetBlacklistedConsumersState,
        addConsumersEntity,
        updateConsumersEntity,
        resetWhitelistedConsumersState,
        changeWhitelistedConsumersExportLoading
    }
} = slice;

export default slice.reducer;
