import { useMemo } from "react";
import { object, string } from "yup";
import { FormField } from "./types";

export default function useValidationSchema() {
    return useMemo(() => object().shape({
        [FormField.Reason]: string()
            .required()
            .nullable()
            .label('Reason'),
        [FormField.OtherReason]: string()
            .max(255)
            .label('Blacklist reason')
    }), []);
};
