import { memo } from "react";
import MuiMenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import { MenuItem } from "consts/consumers";
import type { Consumer } from "features/consumers/types";
import { Blacklist } from "ui/forms/Consumer";
import { Action } from "ui/organizms/Consumers/ConsumerActions";
import { useConsumerContext } from "ui/organizms/Consumers/ConsumerProvider";
import useBlacklistSelected from "./useBlacklistSelected";
import type { BlacklistMenuItemProps } from "../types";

const BlacklistSelected = ({ onSuccess }: BlacklistMenuItemProps) => {
    const { selectionModel } = useConsumerContext();

    const blacklistForm = Blacklist.useForm();

    const onConfirm = useBlacklistSelected(blacklistForm);

    const {
        isValid,
        resetForm
    } = blacklistForm;

    return (
        <Action
            <Consumer>
            group={MenuItem.All}
            TriggerComponent={({ onClick }) => (
                <MuiMenuItem
                    disabled={!selectionModel.length}
                    component='button'
                    onClick={onClick}
                >
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText>Blacklist selected</ListItemText>
                </MuiMenuItem>
            )}
            onConfirm={consumers => (
                onConfirm(consumers)
                    .then(onSuccess)
            )}
            ConfirmButtonProps={{
                sx: {
                    bgcolor: 'black',
                    ':hover': {
                        bgcolor: 'black'
                    }
                },
                children: 'Blacklist',
                disabled: !isValid
            }}
            ModalProps={{
                onClose: () => resetForm(),
                titleSlot: 'Blacklist selected Consumer(s)'
            }}
        >
            <Blacklist.Form
                {...blacklistForm}
            />
        </Action>
    );
};

export default memo(BlacklistSelected);
