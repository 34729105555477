import type { BoxProps } from "@mui/material/Box";
import type { BootstrapableForm } from "types";
import type { useForm } from "hooks";
import type { Consumer } from "features/consumers/types";

export const enum FormField {
    Email = 'email',
    FirstName = 'firstName',
    LastName = 'lastName',
    Active = 'active'
};

export type Create = {
    readonly [FormField.Email]: Consumer['email'];
    readonly [FormField.FirstName]: Consumer['firstName'];
    readonly [FormField.LastName]: Consumer['lastName'];
    readonly [FormField.Active]: Consumer['active'];
};

export type CreateForm =
    & BootstrapableForm<Create, {}>;

export type CreateFormProps =
    & ReturnType<typeof useForm<Create>>
    & BoxProps;

