import { useBlacklistedConsumersActions } from "features/consumers/actions";
import type { BlacklistedConsumer } from "features/consumers/types";
import { NotificationSeverity } from "features/general/types";
import useActions from "features/general/useActions";

export default function useWhitelist() {
    const { showNotication } = useActions();
    const { whitelistConsumers } = useBlacklistedConsumersActions();

    return async (consumers: Array<BlacklistedConsumer>) => {
        await Promise.allSettled(
            consumers.map(({ hashedEmail, consumerHashedEmail }) => whitelistConsumers(
                hashedEmail || consumerHashedEmail
            ).at(0))
        );

        showNotication({
            message: `Selected items were successfully whitelisted`,
            severity: NotificationSeverity.Success
        });
    };
};
