import { memo } from "react";
import Screen from "ui/layouts/Screen";
import { ActiveConsumers } from "ui/organizms/Consumers";

const ActiveConsumersScreen = () => (
    <Screen
        title='Consumers | Active'
    >
        <ActiveConsumers />
    </Screen>
);

export default memo(ActiveConsumersScreen);
