import type { WritableDraft } from "immer/dist/internal";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";
import { MenuItem } from "consts/consumers";
import { compareIds } from "util/support";
import type { CoreBankingEntity, ID, ResourceResponse, ThunkReturnType } from "types";
import type {
    BlacklistedConsumer,
    BlacklistedConsumerResponse,
    Consumer,
    ConsumersResponse,
    ConsumersState,
    ConsumerType,
    WhitelistedConsumer,
    WhitelistedConsumerResponse
} from "./types";
import initialState from "./state";

export const addConsumerEntity = (consumersTab: ConsumerType) => (
    state: WritableDraft<ConsumersState>,
    payload: CoreBankingEntity | void
) => {
    if (!state[consumersTab].tableSlice || !payload) {
        return;
    }

    switch (consumersTab) {
        case MenuItem.Blacklisted: {
            state[consumersTab].tableSlice!.data.unshift(payload as BlacklistedConsumer);

            break;
        }

        case MenuItem.WhiteListed: {
            state[consumersTab].tableSlice!.data.unshift(payload as WhitelistedConsumer);

            break;
        }

        case MenuItem.All: {
            state[consumersTab].tableSlice!.data.unshift(payload as Consumer);

            break;
        }

        default: {
            break;
        }
    }

    state[consumersTab].tableSlice!.to += 1;
    state[consumersTab].tableSlice!.total += 1;
};

export const updateConsumerEntity = (consumersTab: ConsumerType) => (
    state: WritableDraft<ConsumersState>,
    payload: Partial<Consumer>
) => {
    const consumersTableSlice = state[consumersTab].tableSlice as ConsumersResponse['data'];

    state[consumersTab].tableSlice!.data = consumersTableSlice!.data.map(resourceEntity => {
        if (compareIds(resourceEntity.coreId, payload.coreId!)) {
            return {
                ...resourceEntity,
                ...payload
            };
        }

        return resourceEntity;
    });
};

export const postConsumerRequestFulfilled = (
    state: WritableDraft<ConsumersState>,
    { payload }: PayloadAction<ThunkReturnType<ResourceResponse<Consumer>>>
) => {
    state[MenuItem.All].tableLoadingState = ApiState.Succeeded;

    addConsumerEntity(MenuItem.All)(state, payload?.data);
};

export const consumersRequestLoading = (consumersTab: ConsumerType) =>
    (state: WritableDraft<ConsumersState>) => {
        state[consumersTab].tableLoadingState = ApiState.Pending;
    };

export const consumersRequestFulfilled = (consumersTab: ConsumerType) => (
    state: WritableDraft<ConsumersState>,
    { payload }: PayloadAction<ThunkReturnType<ConsumersResponse | BlacklistedConsumerResponse | WhitelistedConsumerResponse>>
) => {
    state[consumersTab].tableLoadingState = ApiState.Succeeded;
    state[consumersTab].tableSlice = payload!.data;
};

export const consumersRequestRejected = (consumersTab: ConsumerType) => (state: WritableDraft<ConsumersState>) => {
    state[consumersTab].tableLoadingState = ApiState.Failed;
};

export const changeExportLoading = (consumersTab: ConsumerType) =>
    (state: WritableDraft<ConsumersState>, { payload }: PayloadAction<ApiState>) => {
        state[consumersTab].exportLoadingState = payload;
    };

export const resetState = (consumersTab: ConsumerType) => (state: WritableDraft<ConsumersState>) => {
    Object.assign(state[consumersTab], initialState[consumersTab]);
};

export const deleteConsumersBlacklistRequestFulfilled = (state: WritableDraft<ConsumersState>, { payload }: PayloadAction<ResourceResponse<ID>>) => {
    state[MenuItem.Blacklisted].tableLoadingState = ApiState.Succeeded;

    const consumersBlacklistTableSlice = state[MenuItem.Blacklisted].tableSlice;
    if (!consumersBlacklistTableSlice) {
        return;
    }

    const { data } = consumersBlacklistTableSlice as BlacklistedConsumerResponse['data'];

    state[MenuItem.Blacklisted].tableSlice!.data = data.filter(({
        coreId,
        hashedEmail,
        consumerHashedEmail
    }) => (
        ![coreId, hashedEmail, consumerHashedEmail]
            .some(hashOrCoreId => compareIds(hashOrCoreId, payload.data))
    ));

    state[MenuItem.Blacklisted].tableSlice!.to -= 1;
    state[MenuItem.Blacklisted].tableSlice!.total -= 1;
};
