import { memo } from "react";
import Typography from "@mui/material/Typography";
import { useTypedSelector } from "hooks";
import { ApiState } from "infrastructure/api";
import { selectAssertConsumersTableLoadingState } from "features/consumers/selectors";
import { NoDataOverlay } from "ui/molecules/TableGrids";
import When from "ui/molecules/When";
import { useConsumerContext } from "ui/organizms/Consumers/ConsumerProvider";

const NoResultsOverlay = () => {
    const { consumersTab } = useConsumerContext();

    return (
        <When
            if={useTypedSelector(selectAssertConsumersTableLoadingState(ApiState.Idle)(consumersTab))}
            then={(
                <NoDataOverlay>
                    <Typography
                        variant='h6'
                        color='textSecondary'
                    >
                        Please select any filters from above in order to return consumer data results.
                    </Typography>
                </NoDataOverlay>
            )}
        />
    );
};

export default memo(NoResultsOverlay);
