import { memo, useCallback, useRef, type PropsWithChildren } from 'react';
import {
    GridToolbarQuickFilter,
    type GridToolbarQuickFilterProps
} from "@mui/x-data-grid-premium";
import Box from '@mui/material/Box';
import { selectIsExportConsumersLoading } from 'features/consumers/selectors';
import { TableToolbar, type TableToolbarProps } from 'ui/widgets/Table';
import { useConsumerContext } from 'ui/organizms/Consumers/ConsumerProvider';

export type ToolbarProps = Pick<
    TableToolbarProps,
    | 'onExport'
    | 'search'
>;

const Toolbar = ({ children, ...restProps }: PropsWithChildren<ToolbarProps>) => {
    const childrenRef = useRef(children);
    childrenRef.current = children;

    const { consumersTab } = useConsumerContext();

    const search = useCallback((props: GridToolbarQuickFilterProps) => (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2
            }}
        >
            {childrenRef.current}
            <GridToolbarQuickFilter
                {...props}
            />
        </Box>
    ), []);

    return (
        <TableToolbar
            {...restProps}
            selectIsExportLoading={selectIsExportConsumersLoading(consumersTab)}
            search={search}
        />
    );
};

export default memo(Toolbar);
