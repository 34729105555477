import { useBlacklistedConsumersActions } from "features/consumers/actions";
import type { BlacklistedConsumer } from "features/consumers/types";
import { NotificationSeverity } from "features/general/types";
import useActions from "features/general/useActions";

export default function useRemoveFromBlacklist() {
    const { showNotication } = useActions();
    const { deleteConsumersBlacklist } = useBlacklistedConsumersActions();

    return async (consumers: Array<BlacklistedConsumer>) => {
        await Promise.allSettled(
            consumers.map(({ coreId, hashedEmail, consumerHashedEmail }) => deleteConsumersBlacklist(
                hashedEmail || consumerHashedEmail || coreId
            ).at(0))
        );

        showNotication({
            message: `Selected items were successfully removed from blacklist`,
            severity: NotificationSeverity.Success
        });
    };
};
