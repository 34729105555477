import { memo } from "react";
import TriggerKYCDialog from "./TriggerKYCDialog";
import MultiTabDialog from "./MultiTabDialog";
import BulkKYCDialog from "./BulkKYCDialog";
import BulkKYCHistoryResultsDialog from "./BulkKYCHistoryResultsDialog";

const ConsumerDialogs = () => (
    <>
        <TriggerKYCDialog />
        <MultiTabDialog />
        <BulkKYCDialog />
        <BulkKYCHistoryResultsDialog />
    </>
);

export default memo(ConsumerDialogs);
