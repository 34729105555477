import { useState, type PropsWithChildren } from "react";
import { buttonClasses } from "@mui/material/Button";
import LoadingButton, { loadingButtonClasses, type LoadingButtonProps } from "@mui/lab/LoadingButton";
import { dialogClasses } from "@mui/material/Dialog";
import { dialogActionsClasses } from "@mui/material/DialogActions";
import { paperClasses } from "@mui/material/Paper";
import { MenuItem } from "consts/consumers";
import { useTypedSelector } from "hooks";
import type { CoreBankingEntity } from "types";
import { selectConsumersById } from "features/consumers/selectors";
import { TriggerableDialog, type TriggerableDialogProps } from "ui/molecules/Dialog";
import ActionPanel from "ui/molecules/ActionPanel";
import { useConsumerContext } from "ui/organizms/Consumers/ConsumerProvider";

type Props<T extends CoreBankingEntity> = Partial<TriggerableDialogProps> & {
    readonly group: MenuItem;
    readonly onConfirm: (resources: Array<T>) => Promise<void>;
    readonly TriggerComponentProps?: LoadingButtonProps;
    readonly ConfirmButtonProps?: LoadingButtonProps;
};

const Action = <T extends CoreBankingEntity>({
    group,
    TriggerComponentProps,
    ConfirmButtonProps,
    onConfirm,
    ...restProps
}: PropsWithChildren<Props<T>>) => {
    const [isProcessing, setProcessing] = useState(false);

    const { selectionModel, setSelectionModel } = useConsumerContext();

    const consumers = useTypedSelector(selectConsumersById(group, selectionModel));

    const handleConfirm = (consumers: Array<T>) => {
        setProcessing(true);

        return onConfirm(consumers)
            .finally(() => {
                setProcessing(false);
                setSelectionModel([]);
            });
    };

    return (
        <TriggerableDialog
            TriggerComponent={({ onClick }) => (
                <LoadingButton
                    color='secondary'
                    variant='outlined'
                    {...TriggerComponentProps}
                    onClick={onClick}
                    disabled={!consumers.length}
                    sx={{
                        [`&.${loadingButtonClasses.root}`]: {
                            color: 'secondary.main'
                        },
                        [`&.${buttonClasses.disabled}`]: {
                            color: 'text.disabled'
                        }
                    }}
                />
            )}
            DialogActionsComponent={({ onClose }) => (
                <ActionPanel
                    sx={{
                        border: 'none',
                        p: 0,
                        m: 0
                    }}
                    CancelActionProps={{
                        disabled: isProcessing
                    }}
                    SaveActionProps={{
                        children: 'Confirm',
                        ...ConfirmButtonProps,
                        loading: isProcessing
                    }}
                    onCancel={onClose}
                    onSave={() => (
                        handleConfirm(consumers)
                            .then(() => onClose())
                    )}
                />
            )}
            {...restProps}
            ModalProps={{
                disableCloseOnBackdropClick: true,
                ...restProps.ModalProps,
                DialogProps: {
                    sx: {
                        [`& .${dialogClasses.container} .${paperClasses.root}`]: {
                            maxWidth: 473
                        },
                        [`& .${dialogActionsClasses.root}`]: {
                            justifyContent: 'center'
                        },
                        '& .MuiDialogContent-dividers': {
                            borderBottom: 'none'
                        }
                    },
                    ...restProps.ModalProps?.DialogProps
                }
            }}
        />
    );
};

export default Action;
