import { memo } from "react";
import type { GridRowParams } from '@mui/x-data-grid-premium';
import ListItem from '@mui/material/ListItem';
import ListItemButton, { type ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import useAuth from "features/auth/useAuth";
import { compareIds } from "util/support";
import When from "ui/molecules/When";

type Props = Pick<ListItemButtonProps, 'onPointerDown'> & GridRowParams;

const Delete = ({ row, onPointerDown }: Props) => {
    const { user } = useAuth();

    const isRowOwner = compareIds(row.coreId, user.coreId);

    return (
        <When
            if={!isRowOwner}
            then={() => (
                <ListItem disablePadding>
                    <ListItemButton
                        onPointerDown={onPointerDown}
                    >
                        <ListItemText
                            primary="Delete"
                            sx={{
                                color: 'error.main'
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            )}
        />
    );
};

export default memo(Delete);
