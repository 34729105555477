import { memo } from "react";
import FiltersActionable from "ui/layouts/FiltersActionable";
import ImportWhitelist from "./ImportWhitelist";
import TriggerKYC from "./TriggerKYC";
import BulkKYC from "./BulkKYC";
import Blacklist from "./Blacklist";
import AddConsumer, { type AddConsumerProps } from "./AddConsumer";

type Props = AddConsumerProps;

const ConsumerActions = (props: Props) => (
    <FiltersActionable>
        <ImportWhitelist
            TriggerComponentProps={{
                variant: 'outlined'
            }}
        />
        <BulkKYC />
        <TriggerKYC />
        <Blacklist />
        <AddConsumer
            {...props}
        />
    </FiltersActionable>
);

export default memo(ConsumerActions);
