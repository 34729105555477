import { memo, type PropsWithChildren } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { TriggerableDialog } from "ui/molecules/Dialog";
import type { FileUploaderProps } from "./types";
import FileDropArea from "./FileDropArea";
import { useFileUploader, useFileRejection } from "./hooks";

const FileUploader = ({
    children,
    before,
    after,
    FileDropAreaProps,
    TriggerComponentProps,
    onSuccess  = _ => _,
    onError = _ => _,
    UploadButtonProps = {},
    ModalProps = {},
    ...restProps
}: PropsWithChildren<FileUploaderProps>) => {
    const fileUploader = useFileUploader(restProps);

    const {
        isProcessing,
        hasFiles,
        upload,
        reset
    } = fileUploader;

    const withReset = (fn?: () => void) => () => {
        fn?.();
        reset();
    };

    return (
        <TriggerableDialog
            TriggerComponent={({ onClick }) => (
                <LoadingButton
                    color='secondary'
                    variant='contained'
                    {...TriggerComponentProps}
                    onClick={onClick}
                />
            )}
            DialogActionsComponent={({ onClose }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    <Button
                        disabled={isProcessing}
                        onPointerDown={withReset(onClose)}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        {...UploadButtonProps}
                        disabled={!hasFiles || UploadButtonProps.disabled}
                        loading={isProcessing}
                        onPointerDown={() => upload()
                            .then(onSuccess)
                            .then(withReset(onClose))
                            .catch(onError)}
                    >
                        Upload Files
                    </LoadingButton>
                </Box>
            )}
            ModalProps={{
                DialogProps: {
                    sx: {
                        '& .MuiDialog-container .MuiPaper-root': {
                            width: 'auto',
                            '& .MuiDialogContent-root': {
                                border: 'none'
                            }
                        },
                        '& .MuiDialogActions-root': {
                            justifyContent: 'flex-end'
                        }
                    }
                },
                ...ModalProps,
                onClose: withReset(ModalProps.onClose)
            }}
            {...restProps}
        >
            {children ?? before}
            <FileDropArea
                {...useFileRejection()}
                {...fileUploader}
                {...FileDropAreaProps}
            />
            {after}
        </TriggerableDialog>
    );
};

export default memo(FileUploader);
