import { useCallback, useRef } from "react";
import {
    postConsumer,
    getConsumerByEmailOrId,
    updateOrCreateConsumerEmails
} from "features/consumers/api";
import { ResourceResponse, Scalar } from "types";
import { Consumer, ConsumerEmailEntity } from "features/consumers/types";
import { TabIndex, TabProps } from "ui/organizms/Consumers/ConsumerDialogs/MultiTabDialog/types";
import type { AddDuplicateEmail } from "ui/forms/Consumer";
import useActions from "features/general/useActions";
import { NotificationSeverity } from "features/general/types";
import { useAppStore } from "hooks";
import { DuplicatedEmailRegistryEntry } from "../DuplicateEmails";
import { processJsonResponse } from "util/api";
import { SamePersonIdentifierSource } from "consts/consumers";

export default function useAddDuplicateEmail({
    getTabPayload,
    tabRegistry: [
        manageDuplicatesRegistry,
        updateManageDuplicatesRegistry
    ],
    dialogRequestProcessing: [, setDialogRequestProcessing]
}: TabProps<TabIndex.ManageDuplicates>) {
    const store = useAppStore();
    const { showNotication } = useActions();
    const addDuplicateEmailFormRef = useRef<ReturnType<typeof AddDuplicateEmail.useAddDuplicateEmailForm>>(null);

    const getFormValues = useCallback(() => {
        const {
            values: {
                email = '',
                samePersonIdentifierSource = ''
            } = {}
        } = addDuplicateEmailFormRef.current ?? {};

        return {
            email: email.trim().toLocaleLowerCase(),
            samePersonIdentifierSource: samePersonIdentifierSource.trim()
        };
    }, []);

    const resetForm = useCallback(() =>
        addDuplicateEmailFormRef.current?.resetForm()
        , []);

    const onReject = useCallback((error: Error) =>
        showNotication({
            message: error.message,
            severity: NotificationSeverity.Error
        }), [showNotication]);

    const handleAddDuplicateEmail = (consumerResourceResponse: ResourceResponse<Consumer>) => {
        if (!consumerResourceResponse.success) {
            throw new Error(consumerResourceResponse.message);
        }

        const { data: consumer } = consumerResourceResponse;
        const {
            email,
            samePersonIdentifierSource
        } = getFormValues();

        const { samePersonIdentifier, hashedEmail } = getTabPayload<Consumer>();

        return updateOrCreateConsumerEmails({
            coreId: consumer.coreId,
            samePersonIdentifier: samePersonIdentifier || hashedEmail,
            email,
            samePersonIdentifierSource: `${SamePersonIdentifierSource.Manual}: ${samePersonIdentifierSource}`,
        })
            .then(processJsonResponse)
            .then((consumerEmailResourseResponse: ResourceResponse<ConsumerEmailEntity>) => {
                if (!consumerEmailResourseResponse.success) {
                    throw new Error(consumerEmailResourseResponse.message);
                }

                updateManageDuplicatesRegistry([
                    new DuplicatedEmailRegistryEntry({
                        ...consumer,
                        email,
                        samePersonIdentifierSource
                    }),
                    ...manageDuplicatesRegistry,
                ]);

                resetForm();
            });
    };

    const handleCreateConsumer = (email: string) =>
        postConsumer({
            hashedEmail: email,
            MOR: store.getState().general.domains.MOR
        })
            .then(processJsonResponse);

    const checkIfEmailAlreadyExists = (email: string) =>
        manageDuplicatesRegistry.some(({ email: existingEmail }) => existingEmail === email);

    const withInvariants = async <T extends Function>(fn: T, args: Array<Scalar>) => {
        const [email] = args;

        if (checkIfEmailAlreadyExists(String(email))) {
            throw new Error('Email already exists');
        }

        return fn(...args);
    };

    const handleGetConsumerByEmail = (email: string) =>
        getConsumerByEmailOrId(email)
            .then(processJsonResponse);

    const onSubmit = () => {
        const { email } = getFormValues();
        setDialogRequestProcessing(true);

        return [
            null,
            withInvariants(handleGetConsumerByEmail, [email])
                .then((consumerResourceResponse: ResourceResponse<Consumer>) => (
                    consumerResourceResponse.data
                        ? Promise.resolve(consumerResourceResponse)
                        : handleCreateConsumer(email)
                ))
                .then(handleAddDuplicateEmail)
                .catch(onReject)
                .finally(() => setDialogRequestProcessing(false))
        ];
    };

    return {
        addDuplicateEmailFormRef,
        onSubmit
    };
};
