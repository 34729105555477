import { useTypedSelector } from "hooks";
import { MenuItem, filters } from "consts/consumers";
import { FilterVariant, date } from "consts/filters";
import { selectIsConsumersLoading } from "features/consumers/selectors";
import { useBlacklistedConsumersActions } from "features/consumers/actions";
import { useConsumerContext } from "../../ConsumerProvider";

export default function useBlacklistedConsumersTable() {
    const isConsumersLoading = useTypedSelector(selectIsConsumersLoading(MenuItem.Blacklisted));

    const { selectionModel, setSelectionModel } = useConsumerContext();

    const {
        getConsumers,
        downloadCsv
    } = useBlacklistedConsumersActions();

    const blacklistedConsumers = filters.get(MenuItem.Blacklisted)!;

    const filterTypes = new Map<FilterVariant, string[]>([
        [FilterVariant.Text, blacklistedConsumers[FilterVariant.Text]!],
        [FilterVariant.DateRange, date]
    ]);

    const isRowSelectable = () => !isConsumersLoading;

    return {
        isConsumersLoading,
        filterTypes,
        selectionModel,
        isRowSelectable,
        getConsumers,
        downloadCsv,
        setSelectionModel
    };
};
