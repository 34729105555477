import { memo, type FC } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import type { DialogTitleProps, ModalProps } from './types';
import { isClosedByReason } from './helpers';

const CustomizedDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        width: '100%',
        maxWidth: 1440
    },

    // [theme.breakpoints.up('sm')]: {

    // }
}));

const CustomizedDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const Modal: FC<ModalProps> = ({
    disableCloseOnBackdropClick,
    titleSlot,
    children,
    actionsSlot,
    onClose,
    isOpen,
    DialogContentProps,
    id = '',
    DialogProps = {},
    DialogTitleProps = {},
    DialogActionsProps = {}
}) => (
    <CustomizedDialog
        {...DialogProps}
        onClose={(...args) => {
            if (
                disableCloseOnBackdropClick &&
                isClosedByReason('backdropClick')(...args)
            ) {
                return;
            }

            onClose(...args);
        }}
        aria-labelledby={id}
        open={isOpen}
    >
        {Boolean(titleSlot) && (
            <CustomizedDialogTitle
                {...DialogTitleProps}
                id={id}
                onClose={onClose}
            >
                {titleSlot}
            </CustomizedDialogTitle>
        )}
        <DialogContent {...DialogContentProps}>
            {children}
        </DialogContent>
        {Boolean(actionsSlot) && (
            <DialogActions {...DialogActionsProps}>
                {actionsSlot}
            </DialogActions>
        )}
    </CustomizedDialog>
);

Modal.defaultProps = {
    DialogContentProps: {
        dividers: true
    }
};

export default memo(Modal);
