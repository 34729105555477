import { memo, useState } from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Create from "ui/forms/Merchant/Create";
import MerchantUser from "ui/forms/Merchant/MerchantUser";
import ActionPanel from "ui/molecules/ActionPanel";
import { ResourceDetail, ResourceDetailProps, useResourceContext } from "ui/widgets/Resource";
import useMerchant from "./useMerchant";
import { MerchantStepForm, isMerchant, isMerchantUser } from "./types";
import { DialogCloseHandler } from "ui/atoms/Modal";

const Merchant = (props: ResourceDetailProps) => {
    const { onClose } = useResourceContext();

    const [activeStep, setActiveStep] = useState(MerchantStepForm.Merchant);

    const merchantMap = useMerchant(setActiveStep);

    const merchantFormState = merchantMap.get(activeStep)!;

    const handleClose: DialogCloseHandler = () => {
        onClose();
        setActiveStep(MerchantStepForm.Merchant);
    };

    const renderFormComponent = () => {
        const merchantFormState = merchantMap.get(activeStep);

        if (!merchantFormState) {
            return null;
        }

        switch (activeStep) {
            case MerchantStepForm.Merchant: {
                if (isMerchant(merchantFormState)) {
                    return (
                        <Create
                            {...merchantFormState}
                        />
                    );
                }

                break;
            }
            case MerchantStepForm.MerchantUser: {
                if (isMerchantUser(merchantFormState)) {
                    return (
                        <MerchantUser
                            {...merchantFormState}
                        />
                    );
                }

                break;
            }
        }

        return null;
    };

    return (
        <ResourceDetail
            disableCloseOnBackdropClick
            {...props}
            onClose={handleClose}
            resourceName={merchantFormState.renderTitle()}
            DialogProps={{
                sx: {
                    "& .MuiDialog-container .MuiPaper-root": {
                        maxWidth: 778
                    }
                }
            }}
            DialogActionsProps={{
                sx: {
                    pt: '0px !important'
                }
            }}
            actionsSlot={(
                <ActionPanel
                    {...merchantFormState.getActionPanelProps()}
                    sx={{
                        width: '100%',
                        mt: 0
                    }}
                />
            )}
        >
            <Stepper
                alternativeLabel
                activeStep={activeStep}
                sx={{
                    mb: 4
                }}
            >
                <Step
                    completed={activeStep > MerchantStepForm.Merchant}
                >
                    <StepLabel>
                        Create new Merchant
                    </StepLabel>
                </Step>
                <Step
                    completed={activeStep > MerchantStepForm.MerchantUser}
                >
                    <StepLabel>
                        Create new Merchant User
                    </StepLabel>
                </Step>
            </Stepper>
            {renderFormComponent()}
        </ResourceDetail>
    );
};

export default memo(Merchant);
