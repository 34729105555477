export const subscribe = (
    eventName: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions
) => {
    document.addEventListener(eventName, listener, options);
};

export const unsubscribe = (
    eventName: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions
) => {
    document.removeEventListener(eventName, listener, options);
};

export const publish = <T>(eventName: string, data: T) =>
    document.dispatchEvent(new CustomEvent(eventName, { detail: data }));

export const withNamespace = (namespace: string, separator = '.') =>
    (eventName: string) => [
        namespace,
        eventName
    ]
        .filter(Boolean)
        .join(separator);
