import { memo } from 'react';
import type { GridRowParams } from '@mui/x-data-grid-premium';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useConsumerContext } from 'ui/organizms/Consumers/ConsumerProvider';

const ViewResults = ({ row }: GridRowParams) => {
    const {
        bulkKYCHistoryResults
    } = useConsumerContext();

    return (
        <ListItem disablePadding>
            <ListItemButton
                onPointerDown={bulkKYCHistoryResults(row)}
            >
                <ListItemText primary="View results" />
            </ListItemButton>
        </ListItem>
    );
};

export default memo(ViewResults);
