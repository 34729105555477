import {
    memo,
    isValidElement,
    type PropsWithChildren,
    type ComponentType,
    type ReactNode,
} from "react";
import { isFunction } from "formik";
import type { Scalar } from "types";
import { exec } from "util/support";

type Expr = Scalar<boolean> | null;
type Compoment = ComponentType | ReactNode;

type Props = {
    readonly if: Expr | (() => Expr);
    readonly then?: Compoment;
    readonly else?: Compoment;
};

const When = ({
    if: If,
    then: Then,
    else: Else,
    children = null
}: PropsWithChildren<Props>) => {
    const render = (Component?: ComponentType | PropsWithChildren['children']) => {
        if (
            !Component ||
            (!isFunction(Component) && !['object'].includes(typeof Component)) ||
            Array.isArray(Component) ||
            isValidElement(Component)
        ) {
            return Component;
        }

        const FunctionComponent = Component as ComponentType;

        return (
            <FunctionComponent />
        );
    };

    return (
        <>
            {exec(If)
                ? render(Then)
                : render(Else || children)}
        </>
    );
};

export default memo(When);
