import { useEffect, useRef } from 'react';
import { subscribe, unsubscribe } from 'util/custom-event';
import * as events from '../events';
import type { FiltersPublicApi } from '../types';

export default function useEvents(filters?: FiltersPublicApi<string> | null) {
    const filtersRef = useRef(filters);
    filtersRef.current = filters;

    useEffect(() => {
        const eventHandlers = new Map<string, () => void>()
            .set(events.apply.eventName, () => filtersRef.current?.onApply());

        Object.values(events)
            .forEach(handler => {
                subscribe(handler.eventName, eventHandlers.get(handler.eventName)!);
            });

        return () => (
            Object.values(events)
                .forEach(handler => {
                    unsubscribe(handler.eventName, eventHandlers.get(handler.eventName)!);
                })
        );
    }, []);
};
