import { CurrencySymbol } from "consts/general";
import {
    TransactionStatsUnit,
    type PaginatedTransactionStats,
    type TransactionStatsAggregator as ITransactionStatsAggregator,
    type TransactionStatsCard
} from './types';
import type { TabIndex, TabProps } from "ui/organizms/Consumers/ConsumerDialogs/MultiTabDialog/types";
import { ThemePaletteMode } from "consts/ui";
import { useThemeMode } from "hooks";

class TransactionStatsAggregator implements ITransactionStatsAggregator {
    public static from(transactionStats: PaginatedTransactionStats | null) {
        return new this(transactionStats);
    }

    private constructor(
        private readonly transactionStats: PaginatedTransactionStats | null = null
    ) { }

    public groupCountByStatus(status: TransactionStatsUnit) {
        return this.findEntityByStatus(status)?.group_count ?? 0;
    }

    public amountProcessed() {
        const transactionEntity = this.findEntityByStatus(TransactionStatsUnit.Suceeded);

        return transactionEntity && [
            transactionEntity.amountEur,
            CurrencySymbol.EUR
        ].join(' ');
    }

    private findEntityByStatus(status: TransactionStatsUnit) {
        return this.transactionStats?.data.find(transaction =>
            Object.is(transaction.status, status)
        );
    }
}

export default function useTransactionStats({
    tabRegistry: [dialogDataRegistry]
}: TabProps<TabIndex.MoreDetails>) {
    const mode = useThemeMode();
    const { transactionStats } = dialogDataRegistry;

    return () => {
        const transactionStatsAggregator = TransactionStatsAggregator.from(transactionStats);

        return new Map<TransactionStatsUnit, TransactionStatsCard>()
            .set(
                TransactionStatsUnit.AmountProcessed,
                {
                    value: transactionStatsAggregator.amountProcessed(),
                    palette: {
                        [ThemePaletteMode.Light]: '#F4F6F8',
                        [ThemePaletteMode.Dark]: '#1E2022'
                    }[mode],
                    title: 'Amount Processed'
                }
            )
            .set(
                TransactionStatsUnit.Suceeded,
                {
                    value: transactionStatsAggregator.groupCountByStatus(TransactionStatsUnit.Suceeded),
                    palette: {
                        [ThemePaletteMode.Light]: 'rgba(0, 171, 85, 0.12)',
                        [ThemePaletteMode.Dark]: '#36FF56'
                    }[mode],
                    title: 'Suceeded trx.'
                }
            )
            .set(
                TransactionStatsUnit.Failed,
                {
                    value: transactionStatsAggregator.groupCountByStatus(TransactionStatsUnit.Failed),
                    palette: {
                        [ThemePaletteMode.Light]: 'rgba(255, 86, 48, 0.12)',
                        [ThemePaletteMode.Dark]: '#FF9166'
                    }[mode],
                    title: 'Failed trx.'
                }
            )
            .set(
                TransactionStatsUnit.Rejected,
                {
                    value: transactionStatsAggregator.groupCountByStatus(TransactionStatsUnit.Rejected),
                    palette: {
                        [ThemePaletteMode.Light]: 'rgba(51, 102, 255, 0.12)',
                        [ThemePaletteMode.Dark]: '#3366FF'
                    }[mode],
                    title: 'Rejected trx.'

                }
            )
            .set(
                TransactionStatsUnit.Chargeback,
                {
                    value: transactionStatsAggregator.groupCountByStatus(TransactionStatsUnit.Chargeback),
                    palette: {
                        [ThemePaletteMode.Light]: 'rgba(255, 171, 0, 0.12)',
                        [ThemePaletteMode.Dark]: '#FFAB00'
                    }[mode],
                    title: 'Chargeback trx.'
                }
            );
    };
};
