import Button from "@mui/material/Button";
import { useModal } from "ui/atoms/Modal";
import { ConsumerIDVerification } from "ui/forms/Consumer";
import { BasicDialog } from "ui/molecules/Dialog";
import { TabIndex, TabProps } from "ui/organizms/Consumers/ConsumerDialogs/MultiTabDialog/types";
import useMarkAsKYC from "../useMarkAsKYC";
import type { Consumer } from "features/consumers/types";
import useIDVerificationConsumerDetails from "./useIDVerificationConsumerDetails";

type Props =
    & ReturnType<typeof useModal>
    & TabProps<TabIndex.MarkAsKYCed>
    & Pick<ReturnType<typeof useMarkAsKYC>, 'onMarkAsKYCed'>;

const IDVerificationConsumerDetails = (props: Props) => {
    const { open, getTabPayload } = props;

    const {
        onSubmit,
        handleClose,
        formikRef
    } = useIDVerificationConsumerDetails(props);

    return (
        <BasicDialog
            isOpen={open}
            onClose={handleClose}
            titleSlot='Consumer verification details'
        >
            <ConsumerIDVerification.Form
                ref={formikRef}
                initialValues={getTabPayload<Consumer>()}
                onSaveOrCreate={onSubmit}
                actionLabel="Submit for verification"
            >
                <Button
                    variant='outlined'
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </ConsumerIDVerification.Form>
        </BasicDialog>
    );
};

export default IDVerificationConsumerDetails;
