import { memo } from "react";
import Screen from "ui/layouts/Screen";
import { WhitelistedConsumers } from "ui/organizms/Consumers";

const WhitelistedConsumersScreen = () => (
    <Screen
        title='Consumers | Whitelisted'
    >
        <WhitelistedConsumers />
    </Screen>
);

export default memo(WhitelistedConsumersScreen);
