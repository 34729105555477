import { BooleanSwitchValue } from "consts/general";
import { Aggregates } from "consts/request-query";
import { Relations } from "consts/consumers";
import { Filters as TransactionFilters } from "consts/transactions";
import { useConsumersActions } from "features/consumers/actions";
import { RequestQueryMapper } from "util/request-query-mapper";

export default function useActiveConsumers() {
    const {
        getConsumers,
        downloadCsv
    } = useConsumersActions();

    const withActiveConsumersQuery = (fn: (params: string) => unknown) =>
        (params: string) => fn(
            RequestQueryMapper.from(params)
                .aggregates(Aggregates.CountRelations, Relations.ChargedBackTransactions)
                .aggregates(Aggregates.CountRelations, Relations.RefundedTransactions)
                .aggregates(Aggregates.CountRelations, Relations.ParentTransactions)
                .aggregates(Aggregates.CountRelations, Relations.SucceededTransactions)
                .aggregates(Aggregates.CountRelations, Relations.FailedTransactions)
                .aggregates(Aggregates.CountRelations, Relations.RejectedTransactions)
                .aggregates(Aggregates.SumsRelations, [Relations.MissingTransactions, TransactionFilters.amountEur])
                .doesntHaveRelations(Relations.ConsumerBlacklistHashedEmails)
                .hasRelations(Relations.Transactions, `${BooleanSwitchValue.On}`, 0)
                .toString()
        );

    const getActiveConsumers = withActiveConsumersQuery(getConsumers);

    const downloadActiveConsumers = withActiveConsumersQuery(downloadCsv);

    return {
        getActiveConsumers,
        downloadActiveConsumers
    };
};
