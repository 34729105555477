import { memo, type SyntheticEvent } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { blacklistReasons } from "consts/consumers";
import { getFormInputProps } from "util/forms";
import { Checkbox } from "ui/forms/formik-elements";
import Autocomplete from "ui/atoms/Autocomplete";
import When from "ui/molecules/When";
import {
    FormField,
    type Blacklist as BlacklistType,
    type BlacklistProps
} from "./types";

const Blacklist = ({
    values,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
    sx,
    component,
    ...form
}: BlacklistProps) => {
    const isOtherReason = (reason: BlacklistType['reason']) => Object.is(
        reason,
        Array.from(blacklistReasons.values()).at(-1)
    );

    const updateOtherReason = () => (
        setFieldValue(FormField.OtherReason, '')
    );

    const onReasonChange = (_: SyntheticEvent, value: string) => {
        setFieldValue(FormField.Reason, value);
        setTimeout(() => setFieldTouched(FormField.Reason, true, true));

        updateOtherReason();
    };

    return (
        <Box
            sx={{
                display: 'grid',
                gap: 2,
                ...sx
            }}
            component={component}
        >
            <Autocomplete
                fullWidth
                disableClearable
                size='small'
                layout={null}
                options={Array.from(blacklistReasons)}
                value={values[FormField.Reason]}
                onChange={onReasonChange}
                TextFieldProps={{
                    ...getFormInputProps(FormField.Reason, form),
                    required: true,
                    name: FormField.Reason,
                    placeholder: 'Select reason',
                    label: 'Select a reason for blacklisting'
                }}
            />
            <When
                if={isOtherReason(values[FormField.Reason])}
                then={(
                    <TextField
                        {...getFormInputProps(FormField.OtherReason, form)}
                        fullWidth
                        multiline
                        rows={4}
                        size='small'
                        name={FormField.OtherReason}
                        value={values[FormField.OtherReason]}
                        label='Write a reason'
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                )}
            />
            <Checkbox
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                label='Blacklist all emails associated (same person)'
                handleBlur={handleBlur}
                CheckboxProps={{
                    inputProps: {
                        'aria-label': 'Blacklist all emails associated (same person)'
                    },
                    name: FormField.AsPerson,
                    checked: values[FormField.AsPerson]
                }}
            />
        </Box>
    );
};

export default memo(Blacklist);
