import { useRef } from 'react';
import { useModal } from "ui/atoms/Modal";
import useMarkAsKYC from "../useMarkAsKYC";
import { useSimpleRequest } from "hooks/useRequest";
import { updateConsumer } from "features/consumers/api";
import { TabIndex, TabProps } from "ui/organizms/Consumers/ConsumerDialogs/MultiTabDialog/types";
import type { ConsumerIDVerification } from "ui/forms/Consumer";
import type { Consumer } from "features/consumers/types";
import { ResourceResponse } from "types";
import { useFormik } from 'formik';
import { useTypedSelector } from 'hooks';
import { selectDomains } from 'features/general/selectors';
import { Filters } from 'consts/transactions';
import { useConsumersActions } from 'features/consumers/actions';

type UseIDVerificationConsumerDetails =
    & ReturnType<typeof useModal>
    & TabProps<TabIndex.MarkAsKYCed>
    & Pick<ReturnType<typeof useMarkAsKYC>, 'onMarkAsKYCed'>;

export default function useIDVerificationConsumerDetails({
    onMarkAsKYCed,
    onClose,
    getTabPayload
}: UseIDVerificationConsumerDetails) {
    const domains = useTypedSelector(selectDomains);
    const formikRef = useRef<ReturnType<typeof useFormik>>(null);
    const request = useSimpleRequest();

    const { updateConsumerById } = useConsumersActions();

    const onSuccess = (resourceResponse: ResourceResponse<Consumer>) => {
        updateConsumerById(resourceResponse.data);
        onMarkAsKYCed();
    };

    const onSubmit = async (formValues: Partial<ConsumerIDVerification.FormValues>) => {
        await request<Consumer>(() => updateConsumer({
            DOB: formValues.DOB,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            verifiedDob: formValues.DOB,
            verifiedFirstName: formValues.firstName,
            verifiedLastName: formValues.lastName,
            coreId: getTabPayload<Consumer>().coreId,
            [Filters.MOR]: domains[Filters.MOR]
        }), {
            onSuccess
        });

        onClose();
    };

    const handleClose = () => {
        if (!formikRef.current?.isSubmitting) {
            onClose();
        }
    };

    return {
        onSubmit,
        handleClose,
        formikRef
    };
};
