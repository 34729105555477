import { useFormik, type FormikConfig } from "formik";
import { BooleanSwitchValue } from "consts/general";
import { FormField, type Blacklist } from "./types";
import useValidationSchema from "./useValidationSchema";

export const getInitialValues = (initialValues: Partial<Blacklist> = {}) => ({
    [FormField.Reason]: null,
    [FormField.OtherReason]: '',
    [FormField.AsPerson]: BooleanSwitchValue.Off,
    ...initialValues
});

export const useForm = (blacklistForm?: FormikConfig<Blacklist>) => {
    const {
        initialValues,
        ...restFormArgs
    } = blacklistForm ?? {};

    return useFormik({
        enableReinitialize: true,
        validateOnMount: true,
        isInitialValid: false,
        ...restFormArgs,
        onSubmit: () => {},
        validationSchema: useValidationSchema(),
        initialValues: getInitialValues(initialValues)
    });
};
