import { memo } from 'react';
import type { GridRowParams } from '@mui/x-data-grid-premium';
import ListItem from '@mui/material/ListItem';
import LoadingButton from '@mui/lab/LoadingButton';
import ListItemText from '@mui/material/ListItemText';
import { useAppDispatch, useTypedSelector } from 'hooks';
import useExportTable from 'hooks/useExportTable';
import { changeExportLoading } from "features/kyc/slice";
import { selectIsExportManualBulkKYCsImportHistoryLoading } from 'features/kyc/selectors';
import { RequestQueryMapper } from 'util/request-query-mapper';
import { ApiRouteTypes } from 'consts/enpoints/api';
import { Filters } from 'consts/consumers';
import { useSimpleRequest } from 'hooks/useRequest';
import type { ConsumersManualBulkKYCsImportHistoryPivot } from 'features/consumers/types';
import type { PaginateResourceResponse } from 'types';
import { getConsumersManualBulkKYCsImportHistoryPivot } from 'features/consumers/api';
import BusinessLogicException from 'exceptions/BusinessLogicException';
import useActions from 'features/general/useActions';
import { NotificationSeverity } from 'features/general/types';
import { ApiState } from 'infrastructure/api';

const DownloadResultsCSV = ({ row }: GridRowParams) => {
    const dispatch = useAppDispatch();

    const { showNotication } = useActions();

    const isDownladResultsLoading = useTypedSelector(selectIsExportManualBulkKYCsImportHistoryLoading);

    const request = useSimpleRequest();

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetConsumersManualBulkKYCsImportHistoryPivot
    });

    const downloadResults = () => {
        const searchQueryParams = RequestQueryMapper.from()
            .containsIn(Filters.manualBulkKycImportHistoryId, row[Filters.coreId])
            .exportVisibility([
                Filters.coreId,
                Filters.MOR,
                Filters.emailOrHash,
                Filters.manualBulkKycImportHistoryId,
                Filters.importStatus,
                Filters.consumerId,
                Filters.createdAt,
                Filters.merchantName,
                Filters.kycTypeName,
                Filters.pki
            ])
            .toString();

        dispatch(changeExportLoading(ApiState.Pending));

        return request<PaginateResourceResponse<ConsumersManualBulkKYCsImportHistoryPivot>['data']>(() =>
            getConsumersManualBulkKYCsImportHistoryPivot(searchQueryParams)
        )
            .then(({ data, success, message }) => {
                if (!data.total) {
                    throw new BusinessLogicException(
                        success
                            ? 'No results found'
                            : message,
                        data
                    );
                }

                return downloadCsv(searchQueryParams);
            })
            .catch(error => showNotication({
                severity: NotificationSeverity.Error,
                message: error.message
            }))
            .finally(() => dispatch(changeExportLoading(ApiState.Idle)));
    };


    return (
        <ListItem disablePadding>
            <LoadingButton
                loading={isDownladResultsLoading}
                onPointerDown={downloadResults}
            >
                <ListItemText primary="Download results (.CSV)" />
            </LoadingButton>
        </ListItem>
    );
};

export default memo(DownloadResultsCSV);
