import { memo } from "react";
import { TableGridMode } from "consts/table";
import { MenuItem } from "consts/consumers";
import useResetState from "hooks/useResetState";
import { resetWhitelistedConsumersState as resetState } from "features/consumers/slice";
import {
    selectConsumersSlice,
    selectIsConsumersLoading,
    selectIsConsumersUninitialized
} from "features/consumers/selectors";
import Table from "ui/widgets/Table";
import * as tableComponents from "ui/organizms/Consumers/ConsumerTable";
import useWhitelistedConsumersTable from "./useWhitelistedConsumersTable";

const WhitelistedConsumersTable = () => {
    const {
        isRowSelectable,
        filterTypes,
        selectionModel,
        setSelectionModel,
        getConsumers,
        downloadCsv
    } = useWhitelistedConsumersTable();

    return (
        <Table
            disableSelectionOnClick
            checkboxSelection
            mode={TableGridMode.WhitelistedConsumer}
            resetState={useResetState(resetState)}
            isRowSelectable={isRowSelectable}
            fetchTableData={getConsumers}
            filterTypes={filterTypes}
            selectIsTableUninitialized={selectIsConsumersUninitialized(MenuItem.WhiteListed)}
            selectIsTableLoading={selectIsConsumersLoading(MenuItem.WhiteListed)}
            selectTableDataSlice={selectConsumersSlice(MenuItem.WhiteListed)}
            downloadCsv={downloadCsv}
            selectionModel={selectionModel}
            onSelectionModelChange={setSelectionModel}
            components={tableComponents}
            LayoutProps={{
                sx: {
                    mx: 0
                }
            }}
            FiltersProps={{
                sx: {
                    mx: 0
                },
                isDefaultFiltersOpen: true
            }}
        />
    );
};

export default memo(WhitelistedConsumersTable);
