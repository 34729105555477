import type { AsyncThunk, AsyncThunkOptions } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";

import type { RootState } from "infrastructure/store";
import { ApiRouteTypes } from "consts/enpoints/api";
import { Filters, MenuItem } from "consts/consumers";
import { BooleanSwitchValue } from "consts/general";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { sliceToken } from "./state";
import type { BlacklistedConsumerResponse, Consumer, ConsumersResponse,WhitelistedConsumerResponse } from "./types";
import {
    deleteConsumersBlacklist,
    getConsumers,
    getConsumersBlacklist,
    getConsumersWhitelist,
    postConsumer,
    updateConsumer
} from "features/consumers/api";
import type { ID, ResourceResponse, ThunkReturnType } from "types";

const getThunkOptions = <TArgs extends unknown>(consumersTab: MenuItem): AsyncThunkOptions<TArgs> => ({
    condition: (_, { getState }) => {
        const { consumers } = getState() as RootState;

        return ![ApiState.Pending].includes(consumers[consumersTab].tableLoadingState);
    }
});

export const getConsumersThunk: AsyncThunk<
    ThunkReturnType<ConsumersResponse>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetConsumers]),
    payloadCreator(getConsumers),
    getThunkOptions<string>(MenuItem.All)
);

export const postConsumerThunk: AsyncThunk<
    ThunkReturnType<ResourceResponse<Consumer>>,
    Partial<Consumer>,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PostConsumer]),
    payloadCreator(postConsumer),
    getThunkOptions(MenuItem.All)
);

export const getBlacklistedConsumersThunk: AsyncThunk<
    ThunkReturnType<BlacklistedConsumerResponse>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetConsumersBlacklist]),
    payloadCreator(getConsumersBlacklist),
    getThunkOptions<string>(MenuItem.Blacklisted)
);
export const getWhitelistedConsumersThunk: AsyncThunk<
    ThunkReturnType<WhitelistedConsumerResponse>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetConsumersWhitelist]),
    payloadCreator(getConsumersWhitelist),
    getThunkOptions<string>(MenuItem.WhiteListed)
);

export const whitelistConsumersThunk: AsyncThunk<
    ResourceResponse<ID>,
    ID,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PutConsumer]),
    async (hashOrCoreID: ID, { rejectWithValue, fulfillWithValue }) => {
        const updateConsumerResponse = await updateConsumer({
            coreId: hashOrCoreID,
            [Filters.active]: BooleanSwitchValue.On
        });

        if (!updateConsumerResponse.ok) {
            return rejectWithValue(updateConsumerResponse.clone());
        }

        let result: ResourceResponse<ID>;

        try {
            result = await deleteConsumersBlacklistResource(hashOrCoreID);
            fulfillWithValue(result);
        } catch (error) {
            return rejectWithValue(error);
        }

        return result;
    }
);

export const deleteConsumersBlacklistThunk: AsyncThunk<
    ResourceResponse<ID>,
    ID,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.DeleteConsumersBlacklist]),
    async (hashOrCoreID: ID, { rejectWithValue, fulfillWithValue }) => {
        let result: ResourceResponse<ID>;

        try {
            result = await deleteConsumersBlacklistResource(hashOrCoreID);
            fulfillWithValue(result);
        } catch (error) {
            return rejectWithValue(error);
        }

        return result;
    }
);

async function deleteConsumersBlacklistResource(hashOrCoreID: ID): Promise<ResourceResponse<ID>> {
    const response = await deleteConsumersBlacklist(hashOrCoreID);

    if (!response.ok) {
        throw response.clone();
    }

    const payload: ResourceResponse<unknown> = await response.json();

    const customPayload: ResourceResponse<ID> = {
        ...payload,
        data: hashOrCoreID
    };

    return customPayload;
}
